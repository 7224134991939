import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service';

@Component({
  selector: 'app-cp-edit-address',
  templateUrl: './cp-edit-address.component.html',
  styleUrls: ['./cp-edit-address.component.css']
})
export class CpEditAddressComponent implements OnInit {
  @Input() selectedProfile!: any;
  editAddressForm!: FormGroup;
  profileObj: any;
  addressObj: any;

  constructor(public formBuilder: FormBuilder,public router: Router, public service:ApiService) { }
  ngAfterViewInit(): void {
    console.log(this.selectedProfile?.cp_profile_id);
  }
  ngOnInit(): void {
    this.initAddressForm();
    this.editableForm();

  }

  initAddressForm() {
    this.editAddressForm = this.formBuilder.group({
      id: '',
      address_line_one: ['', Validators.required],
      address_line_two: ['', Validators.required],
      address_line_three: ['', Validators.required],
      city: ['', Validators.required],
      pincode: ['', Validators.required],
      country: ['', Validators.required],
    });

  }
  editableForm(){
    this.service.getAddressDetails(this.selectedProfile?.cp_profile_id).subscribe((response: any) => {
      console.log(response);
      let AddressDetails = response.data[0];
      this.addressObj = {
        "id": AddressDetails.id,
        "address_line_one": AddressDetails.address_line_one,
        "address_line_two": AddressDetails.address_line_two,
        "address_line_three": AddressDetails.address_line_three,
        "city": AddressDetails.city,
        "pincode": AddressDetails.pincode,
        "country": AddressDetails.country,
      }
      // this.aboutObj.controls['languages'].value 
      console.log(this.addressObj)

      this.editAddressForm.setValue(this.addressObj);
    });
    
  }

  updateAddress(){
    const updateProfileObj = {
      "cp_profile_id": this.selectedProfile?.cp_profile_id,
      "review_type": "address",
      "old_value": JSON.stringify(this.addressObj),
      "new_value": JSON.stringify(this.editAddressForm.value),
      "review_status": "PENDING",
      "approved_by": "",
      "approved_on": "",
    }
    this.service.saveCpAddressReview(updateProfileObj).subscribe(res => {
      console.log(res);
    });
  }

}
