import { environment } from "src/environments/environment";

export const CURRENT_USER = "CURRENT_USER";
export const CURRENT_USER_ID = "CURRENT_USER_ID";
export const UNAME = "UNAME";
export const TOKEN = "TOKEN";
export const IS_LOGGEDIN = "IS_LOGGEDIN";
export const ACTIVE_BLOGS = "ACTIVE_BLOGS";
export const SELECTED_BLOG = "SELECTED_BLOG";
export const USER_TYPE = "USER_TYPE";
export const CUNIQUE_ID = "CUNIQUE_ID";
export const CORP = "CORP";
export const INDI = "INDI";
export const CURRENT_SUBSCRIPTION = "CURRENT_SUBSCRIPTION";
export const HAVE_ACTIVE_SUBSCRIPTION = "HAVE_ACTIVE_SUBSCRIPTION";
export const SESSION_EXPIRES_ON = "SESSION_EXPIRES_ON";
export const LINKED_ACCOUNT = "LINKED_ACCOUNT";
export const HAS_MOOD_TRACKER_DATA = "HAS_MOOD_TRACKER_DATA";
export const HAVE_SUBSCRIPTION = "HAVE_SUBSCRIPTION";
// onmood9@gmail.com
export const FACEBOOK_APP_ID = "3014469375503164";//" 1340340410072041";
// rajanikanthchintakayala@gmail.com 
// export const FACEBOOK_APP_ID = "3161510020628470";//"202020561128064";
export const FACEBOOK_SDK_URL = "https://connect.facebook.net/en_US/sdk.js";
// export const FACEBOOK_USER_DETAILS_URL = "https://graph.facebook.com/{fb-user-id}?fields=name,email,birthday&access_token={access-token}"
export const FACEBOOK_USER_DETAILS_URL = "https://graph.facebook.com/{fb-user-id}?fields=name,email&access_token={access-token}"

// export const GOOGLE_APP_ID = "376812513328-8ci6lq9qfj1over3d2h0qo3shtt2ot4h.apps.googleusercontent.com";
// export const GOOGLE_APP_SECRET_ID = "a71olEeLQsN89xJPMam-CE4L";



// onmood9@gmail.com 
export const ONMOOD9_GOOGLE_APP_ID = "3302985111021-pjfe2cnk0f6oohntnqhfr3sbpnuea73m.apps.googleusercontent.com";
export const ONMOOD9_GOOGLE_APP_SECRET_ID = "vQwDFqvoL3seEQHVngC5j0D-";


// onmood9@gmail.com -  webclient-3
// export const ONMOOD9_GOOGLE_APP_ID = "302985111021-shcbnnbbbn4cl24gavfr2a5rldl4499m.apps.googleusercontent.com";
// export const ONMOOD9_GOOGLE_APP_SECRET_ID = "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";




// export const ONMOOD9_GOOGLE_APP_ID = "302985111021-op2ecaoemenslhvi19loberantsdhhol.apps.googleusercontent.com";
// export const ONMOOD9_GOOGLE_APP_SECRET_ID = "tQtRo5utnkzhqwP_a-xb64bl";


export const USER_SESSION_KEYS = [
  CURRENT_USER,        
  UNAME, 
  TOKEN, 
  IS_LOGGEDIN,
  USER_TYPE, 
  CUNIQUE_ID, 
  LINKED_ACCOUNT, 
  CURRENT_SUBSCRIPTION,
  SESSION_EXPIRES_ON,
  HAS_MOOD_TRACKER_DATA
];



export const UNPLEASANT_EMOTIONS = [
    {
      index: 0,
      id: 'mood1',
      code: 'M8',
      title: 'Angry',
      isSelected: false,
      image: 'assets/img/m1.svg',
      synonyms: ['Furious', 'Resentment', 'Envy', 'Annoyed'],
      thoughtLinks: [
        '/onmood-course/1/course/35/group/65/modules',
        '/onmood-course/1/course/35/group/66/modules',
        '/onmood-course/1/course/35/group/67/modules',
        '/onmood-course/1/course/35/group/68/modules',
        '/onmood-course/3/course/29/group/73/modules',
      ]
    },
    {
      index: 1,
      id: 'mood2',
      code: 'M7',
      title: 'Anxiety & Fear',
      isSelected: false,
      image: 'assets/img/m2.svg',
      synonyms: ['Terrified', 'Afraid', 'Worried', 'Concerned'],
      thoughtLinks: [
        '/onmood-course/1/course/33/group/57/modules',
        '/onmood-course/1/course/33/group/58/modules',
        '/onmood-course/1/course/33/group/59/modules',
        '/onmood-course/1/course/33/group/60/modules',
        '/onmood-course/3/course/29/group/27/modules',
      ]
    },
    {
      index: 2,
      id: 'mood3',
      code: 'M5',
      title: 'Disgust',
      isSelected: false,
      image: 'assets/img/m3.svg',
      synonyms: ['Hateful', 'Self Pity', 'Embarrassed', 'Guilty'],
      thoughtLinks: [
        '/onmood-course/1/course/39/group/86/modules',
        '/onmood-course/1/course/39/group/87/modules',
        '/onmood-course/1/course/38/group/82/modules',
        '/onmood-course/1/course/39/group/89/modules',
        '/onmood-course/3/course/29/group/84/modules',
      ]
    },
    {
      index: 3,
      id: 'mood4',
      code: 'M6',
      title: 'Depression & Sad',
      isSelected: false,
      image: 'assets/img/m4.svg',
      synonyms: ['Grieved', 'Hopeless', 'Dejected', 'Unhappy'],
      thoughtLinks: [
        '/onmood-course/1/course/34/group/61/modules',
        '/onmood-course/1/course/34/group/62/modules',
        '/onmood-course/1/course/34/group/63/modules',
        '/onmood-course/1/course/34/group/64/modules',
        '/onmood-course/3/course/29/group/47/modules',
      ]
    },
  ];
  
  export const PLEASANT_EMOTIONS = [
    {
      index: 0,
      id: 'mood1',
      code: 'M3',
      title: 'Happiness',
      isSelected: false,
      image: 'assets/img/hap1.svg',
      synonyms: ['Euphoria', 'Elated', 'Joyful', 'Glad'],
      thoughtLinks: [
        '/onmood-course/1/course/36/group/74/modules',
        '/onmood-course/1/course/36/group/75/modules',
        '/onmood-course/1/course/36/group/76/modules',
        '/onmood-course/1/course/36/group/91/modules',
        '/onmood-course/3/course/29/group/72/modules',
      ]
    },
    {
      index: 1,
      id: 'mood2',
      code: 'M4',
      title: 'Love',
      isSelected: false,
      image: 'assets/img/hap2.svg',
      synonyms: ['Passionate', 'Attached', 'Affection', 'Compassion'],
      thoughtLinks: [
        '/onmood-course/1/course/37/group/77/modules',
        '/onmood-course/1/course/37/group/78/modules',
        '/onmood-course/1/course/37/group/79/modules',
        '/onmood-course/1/course/37/group/92/modules',
        '/onmood-course/3/course/29/group/84/modules',
      ]
    },
    {
      index: 2,
      id: 'mood3',
      code: 'M1',
      title: 'Resilience',
      isSelected: false,
      image: 'assets/img/hap3.svg',
      synonyms: ['Fearless', 'Arrogant', 'Brave', 'Pride'],
      thoughtLinks: [
        '/onmood-course/1/course/38/group/80/modules',
        '/onmood-course/1/course/38/group/81/modules',
        'onmood-course/1/course/38/group/82/modules',
        '/onmood-course/1/course/38/group/90/modules',
        '/onmood-course/3/course/29/group/83/modules',
      ]
    },
    {
      index: 3,
      id: 'mood4',
      code: 'M2',
      title: 'Wonder',
      isSelected: false,
      image: 'assets/img/hap4.svg',
      synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
      thoughtLinks: [
        '/onmood-course/1/course/36/group/74/modules',
        '/onmood-course/1/course/36/group/75/modules',
        '/onmood-course/1/course/36/group/76/modules',
        '/onmood-course/1/course/36/group/91/modules',
        '/onmood-course/3/course/29/group/85/modules',
      ]
    },
  ];


  export const QUESTION_3 = {
    questionText: 'How you want to destress yourself ?',
    questionTextOptions: [
      {index: 0, text: 'Destress Meditation', link:'/onmood-course/2/course/28/group/24/modules'},
      {index: 1, text: 'Destress Music', link:'/onmood-course/3/course/29/group/26/modules'},
      {index: 2, text: 'Manage Intense emotions', link:'/onmood-course/2/course/28/group/23/modules'},
      {index: 3, text: 'Natural Sounds', link:'/onmood-course/3/course/29/group/25/modules'}
    ]
  };

  export const QUESTION_4 = {
    questionText: 'Which Meditation you will prefer now ?',
    questionTextOptions: [
      {index: 0, text: 'Basic meditation', link:'/onmood-course/2/course/32/group/34/modules'},
      {index: 1, text: 'Mindfulness', link:'/onmood-course/2/course/28/group/39/modules'},
      {index: 2, text: 'Selfcare', link:'/onmood-course/2/course/32/group/38/modules'},
      {index: 3, text: 'Self healing', link:'/onmood-course/2/course/32/group/41/modules'},
      {index: 4, text: 'Positive attitude', link:'/onmood-course/2/course/32/group/53/modules'},
      {index: 5, text: 'Morning Meditation', link:'/onmood-course/2/course/28/group/52/modules'},
      {index: 6, text: 'Embracing Empathy', link:'/onmood-course/2/course/32/group/70/modules'},
    ]
  };

  export const QUESTION_5 = {
    questionText: 'Which Music will you prefer ?',
    questionTextOptions: [
      {index: 0, text: 'Natural Sounds', link:'/onmood-course/3/course/29/group/25/modules'},
      {index: 1, text: 'Stress Buster', link:'/onmood-course/3/course/29/group/26/modules'},
      {index: 2, text: 'Reduce Anxiety', link:'/onmood-course/3/course/29/group/27/modules'},
      {index: 3, text: 'Overcome Depression', link:'/onmood-course/3/course/29/group/47/modules'},
      {index: 4, text: 'Increase Joy', link:'/onmood-course/3/course/29/group/72/modules'},
      {index: 5, text: 'Improve Courage', link:'/onmood-course/3/course/29/group/83/modules'},
      {index: 6, text: 'Calm and Blissful', link:'/onmood-course/3/course/29/group/73/modules'},
      {index: 7, text: 'Love & Compassion', link:'/onmood-course/3/course/29/group/84/modules'},
      {index: 8, text: 'Wonder & Amazement', link:'/onmood-course/3/course/29/group/85/modules'},
    ]
  };

  export const QUESTION_6 = {
    questionText: 'How you want to proceed to Sleep?',
    questionTextOptions: [
      {index: 0, text: 'Prepare for deep Sleep through exercise', link:'/onmood-course/3/course/30/group/30/modules'},
      {index: 1, text: 'Sleep Training', link:'/onmood-course/3/course/30/group/42/modules'},
      {index: 2, text: 'Through Sleep Meditation', link:'/onmood-course/3/course/30/group/43/modules'},
      {index: 3, text: 'With help of Sleep Music', link:'/onmood-course/3/course/30/group/54/modules'},
    ]
  };

  export const QUESTION_7 = {
    questionText: 'What you want to learn about Emotions & Moods?',
    questionTextOptions: [
      {index: 0, text: 'Selfawareness', link:'/onmood-course/1/course/31/group/31/modules'},
      {index: 1, text: 'Explore thoughts', link:'/onmood-course/1/course/31/group/33/modules'},
      {index: 2, text: 'Identify Emotions', link:'/onmood-course/1/course/31/group/36/modules'},
      {index: 3, text: 'Label Moods', link:'/onmood-course/1/course/31/group/37/modules'},
    ]
  };

  export const QUESTION_9 = {
    questionText: 'Take care of Personal Emotions...',
    questionTextOptions: [
      {index: 0, text: 'Feeling Demotivated & Lost', link:'/onmood-course/2/course/28/group/52/module/167/sessions'},
      {index: 1, text: 'Overcome Addiction', link:'/onmood-course/2/course/32/group/41/module/126/sessions'},
      {index: 2, text: 'Fear of flight or travel', link:'/onmood-course/1/course/38/group/81/module/318/sessions'},
      {index: 3, text: 'Feeling Resentful', link:'/onmood-course/1/course/37/group/78/module/331/sessions'},
      {index: 4, text: 'Control Craving', link:'/onmood-course/2/course/28/group/93/module/366/sessions'}, 
      {index: 5, text: 'Feeling Hopeless & Depressed', link:'/onmood-course/2/course/28/group/23/module/94/sessions'},
      {index: 6, text: 'Overcome Panic', link:'/onmood-course/1/course/34/group/61/module/238/sessions'},
      {index: 7, text: 'Facing Fear of rejection or Low self esteem', link:'/onmood-course/2/course/32/group/38/module/121/sessions'},
      {index: 8, text: 'Feeling Insecure', link:'/onmood-course/1/course/38/group/90/module/356/sessions'},
      {index: 9, text: 'Loosing Self Confidence', link:'/onmood-course/1/course/38/group/82/module/323/sessions'},
      {index: 10, text: 'Feeling Burnout', link:'/onmood-course/2/course/28/group/24/module/111/sessions'},
      {index: 11, text: 'Feeling Guilt or Shame', link:'/onmood-course/2/course/32/group/38/module/122/sessions'},
      {index: 12, text: 'Shut the Inner critic', link:'/onmood-course/2/course/32/group/38/module/120/sessions'},
      {index: 13, text: 'Stuck in Negative thinking', link:'/onmood-course/2/course/32/group/34/module/154/sessions'},
      {index: 14, text: 'Feeling Melancholy & Lack of enthusiasm', link:'/onmood-course/1/course/36/group/76/module/312/sessions'},

    ]
  };



  export const QUESTION_10 = {
    questionText: 'Manage Social Emotions...',
    questionTextOptions: [
      {index: 0, text: 'Tension of meeting or exam', link:'/onmood-course/2/course/28/group/23/module/84/sessions'},
      {index: 1, text: 'Beat Monday blues', link:'/onmood-course/2/course/28/group/52/module/168/sessions'},
      {index: 2, text: 'Avoid losing temper', link:'/onmood-course/2/course/28/group/23/module/86/sessions'},
      {index: 3, text: 'Office Anxiety', link:'/onmood-course/1/course/35/group/65/module/183/sessions'},
      {index: 4, text: 'Feeling nervous about the outcome', link:'/onmood-course/2/course/28/group/23/module/85/sessions'},
      {index: 5, text: 'Coping with Grief', link:'/onmood-course/1/course/36/group/75/module/305/sessions'},
      {index: 6, text: 'Need a Mindful break', link:'/onmood-course/2/course/28/group/39/module/114/sessions'},
      {index: 7, text: 'Make boring commute more meaningful', link:'/onmood-course/2/course/28/group/39/module/115/sessions'},
      {index: 8, text: 'Preparing for presentation or public speaking', link:'/onmood-course/1/course/38/group/82/module/324/sessions'},
      {index: 9, text: 'Preparing for a Stressful situation', link:'/onmood-course/1/course/36/group/76/module/325/sessions'},
      {index: 10, text: 'Feeling Betrayed', link:'/onmood-course/2/course/32/group/70/module/191/sessions'},
      {index: 11, text: 'Facing Conflicts or Injustice', link:'/onmood-course/2/course/32/group/70/module/192/sessions'},
      {index: 12, text: 'Want to overcome apathy', link:'/onmood-course/2/course/32/group/70/module/193/sessions'},
      {index: 13, text: 'Want to mend a strained relationship', link:'/onmood-course/2/course/32/group/70/module/194/sessions'},
      {index: 14, text: 'Dealing with distractions', link:'/onmood-course/2/course/32/group/70/module/89/sessions'}
    ]
  };
 


  export const THOUGHT_OPTIONS = {
    legend: { position: 'none' },
    series: {
      0: { color: '#808000' },
      1: { color: '#00008B' },
      2: { color: '#800000' },
    },
    tooltip: { isHtml: true },
    vAxis: {
      ticks: [
        { v: 0, f: '0%' },
        { v: 20, f: '20%' },
        { v: 40, f: '40%' },
        { v: 60, f: '60%' },
        { v: 80, f: '80%' },
        { v: 100, f: '100%' },
      ],
      gridlines: { color: '#dedede' },
      textStyle: { color: '#5f5f5f' },
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
        max: 100,
      },
    },
  };

export const PIE_CHART_COLORS = [ '#FEA500',
  '#CBD109',
  '#FFD700',
  '#2DB12D',
  '#1870C5',
  '#7228bc',
  '#D36BD3',
  '#FE0000' 
];
  export const MOOD_COLORS = [
    '#FEA500',
    '#CBD109',
    '#FFD700',
    '#2DB12D',
    '#1870C5',
    '#7228bc',
    '#D36BD3',
    '#FE0000',
  ];

export const MOOD_OPTIONS_WEB = {
    legend: { position: 'right' },
    pieHole: 0,
    // pieSliceText: 'label',
    is3D: false,
    width: 400,
    height: 400,
    sliceVisibilityThreshold: 0,
    pieSliceTextStyle: {
      fontSize: 9,
    },
    colors: MOOD_COLORS,
    tooltip: { isHtml: true },
};
export const MOOD_OPTIONS_MOBILE = {
  legend: { position: 'bottom', alignment: 'end' },
  pieHole: 0,
  // pieSliceText: 'label',
  is3D: false,
  width: 300,
  height: 300,
  sliceVisibilityThreshold: 0,
  pieSliceTextStyle: {
    fontSize: 9,
  },
  colors: MOOD_COLORS,
  tooltip: { isHtml: true },
};

export const THOUGHT_ONE = {
  index: 0,
  id: 'thought1',
  code: 'T1',
  title: 'Thought is related to - I, Me & Myself',
  isSelected: false,
  images: [
    { name: 'My Ego', src: 'assets/img/ic1.webp' },
    { name: 'My Belongings', src: 'assets/img/ic2.webp' },
    { name: 'My Career', src: 'assets/img/ic3.webp' },
    { name: 'My Health', src: 'assets/img/ic4.webp' },
    { name: 'My Achievements', src: 'assets/img/ic5.webp' },
    // { name: 'My Finances', src: 'assets/img/ic6.webp' },
    { name: 'My Feelings', src: 'assets/img/ic7.webp' },
    // { name: 'My Comfort', src: 'assets/img/ic8.webp' },
    // { name: 'My Image', src: 'assets/img/ic9.webp' }
  ]
};


export const THOUGHT_TWO = {
  index: 1,
  id: 'thought2',
  code: 'T2',
  title: 'Thought is related to Family, Friends & Colleagues',
  isSelected: false,
  images: [
    { name: 'Partner', src: 'assets/img/ic10.webp' },
    // { name: 'Siblings', src: 'assets/img/ic11.webp' },
    { name: 'Colleagues', src: 'assets/img/ic12.webp' },
    { name: 'Kids', src: 'assets/img/ic13.webp' },
    { name: 'Relationships', src: 'assets/img/ic14.webp' },
    // { name: 'Classmates', src: 'assets/img/ic15.webp' },
    { name: 'Parents', src: 'assets/img/ic16.webp' },
    { name: 'Friends', src: 'assets/img/ic17.webp' },
    // { name: 'Competitors', src: 'assets/img/ic18.webp' }
  ],
};

 export const THOUGHT_THREE =  {
    index: 2,
    id: 'thought3',
    code: 'T3',
    title: 'Thought is related to Community, Society & Public',
    isSelected: false,
    images: [
      { name: 'Neighbours', src: 'assets/img/ic19.webp' },
      { name: 'Society ', src: 'assets/img/ic20.webp' },
      // { name: 'Public Health', src: 'assets/img/ic21.webp' },
      // { name: 'Community welfare', src: 'assets/img/ic22.webp' },
      { name: 'People ', src: 'assets/img/ic23.webp' },
      { name: 'Environment ', src: 'assets/img/ic24.webp' },
      { name: 'Community ', src: 'assets/img/ic25.webp' },
      // { name: 'Social Service', src: 'assets/img/ic26.webp' },
      { name: 'Global ', src: 'assets/img/ic27.webp' }
    ],
};

export const THOUGHTS_DATA = [THOUGHT_ONE, THOUGHT_TWO, THOUGHT_THREE];
export const RESILIENCE = 'Resilience';
export const WONDER = 'Wonder';
export const JOY = 'Joy';
export const LOVE = 'Love';
export const DISGUST = 'Disgust';
export const DEPRESSION = 'Depression & Sadness';
export const ANXIETY = 'Anxiety & Fear';
export const ANGER = 'Anger';

export const LEARN_ID = 1;
export const MEDITATE_ID = 2;
export const RELAX_ID = 3;
export const MEDITATION_SERIES_ID = environment.production ? 32 : 28; //28;//Testing
export const MEDITATION_SINGLE_ID = environment.production ? 28 : 31; //31;//Testing
export const RELAX_MUSIC_ID = environment.production ? 29 : 29;//Testing

export const HOME_PAGE_ID = 1;

export const MEDITATION_SERIES_HOME_PAGE_IMAGE_WIDTH = 150;
export const MEDITATION_SERIES_HOME_PAGE_IMAGE_HEIGHT = 100;
export const MEDITATION_SERIES_HOME_PAGE_CAROSAL_HEIGHT = 150;