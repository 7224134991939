<div class="card">
    <div class="card-header">
        <small>About</small>
    </div>
    <div class="card-body">
        <form [formGroup]="editAboutForm">
            <div class="form-group">
                <label for="brief">Brief:</label>
                <textarea class="form-control" id="brief" type="text" formControlName="brief"
                    name="brief" placeholder="brief"></textarea>
            </div>
            <div class="form-group">
                <label for="experience">Experience: {{aboutObj?.experience}}</label>
                    {{expY}}-{{expM}}
                    <div class="input-group mb-6">
                        <input (change)="changeExp($event, 'Y')" type="range" id="expY" formControlName="expY" name="expY" min="0" max="50">
                        <label class="input-group-text" for="inputGroupSelect01">{{expY}} Years</label>
                    </div>
                    <div class="input-group mb-6">
                        <input (change)="changeExp($event, 'M')" type="range" id="expM" formControlName="expM" name="expM" min="0" max="11"/>
                        <label class="input-group-text" for="inputGroupSelect02">{{expM}} Months</label>

                </div>
            </div>
            <div class="form-group">
                <label for="fee">Fee: </label>
                <input class="form-control" id="fee" type="text" formControlName="fee" name="fee"
                    placeholder="fee" />
                <!-- <div class="form-control">
                    <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
                </div> -->
            </div>
            <div class="form-group">
                <label for="available_days">Available Days:</label>
                <div *ngFor="let day of days; let i=index" class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [value]="day" [checked]="addedCpAvailableDayList.indexOf(day) >= 0" (change)="onDayChange($event, day)" id="{{day}}">
                        <label class="form-check-label" for="{{day}}">
                            {{day}}
                        </label>
                      </div>
                   
                </div>
            </div>
            <div class="form-group">
                <label for="work_hours">Work Hours: {{aboutObj?.work_hours}}</label><br>
                <!-- <div class="form-control"> -->
                    <label for="startTime">Start Time:</label>
                    <input class="form-control" id="work_hours" type="time" formControlName="startTime"
                        name="startTime" placeholder="work_hours" (change)="setTime($event, 'start')" />

                    <label for="endTime">End Time:</label>
                    <input class="form-control" id="work_hours" type="time" formControlName="endTime"
                        name="endTime" placeholder="work_hours" (change)="setTime($event, 'end')" />
                <!-- </div> -->
            </div>
            
            <div class="form-group">
                <label>Mode</label><br>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="mode" value="ONLINE"
                        formControlName="mode" id="inlineRadio4">
                    <label class="form-check-label" for="inlineRadio4">Online</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="mode" value="OFFLINE"
                        formControlName="mode" id="inlineRadio5">
                    <label class="form-check-label" for="inlineRadio5">Offline</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="mode" value="BOTH"
                        formControlName="mode" id="inlineRadio6">
                    <label class="form-check-label" for="inlineRadio6">Both</label>
                </div>
            </div>

            <div class="form-group">
                <label for="languages">Languages:</label>
                <div *ngFor="let language of languages; let i=index" class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [value]="language" (change)="onLanguageChange($event, language)"  [checked]="addedCpLanguageList.indexOf(language) >= 0" id="{{language}}">
                        <label class="form-check-label" for="{{language}}">
                            {{language}}
                        </label>
                      </div>
                   
                </div>
            </div>
            <!-- <div class="form-group">
                <label for="languages">Languages:</label>
                <div *ngFor="let language of languages; let i=index" class="col-md-6">
                    <label>
                        <input type="checkbox" [value]="language" 
                        [checked]="addedCpLanguageList.indexOf(language) >= 0"
                        (change)="onLanguageChange($event, language)">
                        {{language}}
                    </label>
                </div>
            </div> -->

            <div class="form-group">
                <label for="education">Education:</label>
                <textarea class="form-control" id="education" type="text" formControlName="education"
                    name="education" placeholder="education"></textarea>
            </div>
            <div class="form-group">
                <label for="achievements">Achievements:</label>
                <textarea style="height: 50px;" class="form-control" id="achievements" type="text"
                    formControlName="achievements" name="education"
                    placeholder="achievements"></textarea>
            </div>


            <br />
            <button class="btn btn-primary" (click)="updateAbout()">update</button>
        </form>
    </div>
</div>