import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service';

@Component({
  selector: 'app-cp-edit',
  templateUrl: './cp-edit.component.html',
  styleUrls: ['./cp-edit.component.css']
})
export class CpEditComponent implements OnInit {
  cpProfileId: string = "";
  selectedProfile: any;
  constructor(private route: ActivatedRoute, public router: Router,private service:ApiService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.cpProfileId = params.get(['cpId']);
      console.log(this.cpProfileId);
      this.getProfileDetails(this.cpProfileId);
    });
  }
  getProfileDetails (cpProfileId: string){
  this.service.getProfileDetails(cpProfileId).subscribe((response: any) => {
    console.log(response);
    this.selectedProfile = response.data[0];
  });
}

goBack() {
  this.router.navigate(["/cp-login"]);
}
  
}
