import { Injectable,Output, EventEmitter  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router} from '@angular/router';

import { User } from "../model/user";
import { StorageService } from './storage.service';
import { CUNIQUE_ID, CURRENT_SUBSCRIPTION, CURRENT_USER, FACEBOOK_APP_ID, FACEBOOK_USER_DETAILS_URL, IS_LOGGEDIN, LINKED_ACCOUNT, SESSION_EXPIRES_ON, TOKEN, UNAME, USER_SESSION_KEYS, USER_TYPE } from '../constants/Constants';
import { DateUtil } from '../Utils/DateUtil';
import { PARTNER_SESSION_KEYS } from '../constants/PartnerConstants';
import { PARTNER_USER_LOGIN_URL, PARTNER_SUB_USER_LOGIN_URL, USER_LOGIN_URL, PARTNER_USER_TERMS_URL } from '../constants/ApiEndPoints';

declare var FB: any;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    @Output() getLoggedInUserEmail: EventEmitter<any> = new EventEmitter();
    @Output() userEvent: EventEmitter<any> = new EventEmitter();
    @Output() hideFooterEvent: EventEmitter<any> = new EventEmitter();
    @Output() hideHeaderEvent: EventEmitter<any> = new EventEmitter();
    @Output() hideNavMenuEvent: EventEmitter<any> = new EventEmitter();
    @Output() userLogoutEvent: EventEmitter<any> = new EventEmitter();
    @Output() partnerLogoutEvent: EventEmitter<any> = new EventEmitter();
    @Output() fbUserLoginEvent: EventEmitter<any> = new EventEmitter();
    @Output() deviceBackButtonEvent: EventEmitter<any> = new EventEmitter();
    subsVar:any; // Subscription;

    private currentUserSubject: any;//: BehaviorSubject<User>;
    public currentUser: any;//: Observable<User>;

    constructor(private http: HttpClient, private router:Router, private storageService: StorageService) { 
        // this.subsVar = null;
        this.fbSetup();
    }

    isLoggedIn(): boolean {
        return !!this.currentUser;
    }

    partnerUserLogin(partnerApp: string, corporate_id: string, encrypted: string) {
        let input = new FormData();
        input.append("cunique_id", corporate_id);
        input.append("input", encrypted);
        input.append("partnerFrom", partnerApp);
        return this.http.post<any>(PARTNER_USER_LOGIN_URL, input)
            .pipe(map(response => {
                // console.log("=======>" + JSON.stringify(response));
                return response;
            }));
    }

    partnerUserLogin2(partnerApp: string, corporate_id: string, encrypted: string, type: string) {
        let input = new FormData();
        input.append("cunique_id", corporate_id);
        input.append("input", encrypted);
        input.append("partnerFrom", partnerApp);
        let url = ''; 
        if(type === 'p') {
            url = PARTNER_USER_LOGIN_URL
        } else if(type === 'sc') {
            url = PARTNER_SUB_USER_LOGIN_URL;
        }
        return this.http.post<any>(url, input)
            .pipe(map(response => {
                // console.log("=======>" + JSON.stringify(response));
                return response;
            }));
    }


    login(username: string, password: string) {
        let input = new FormData();
        input.append("email", username);
        // input.append("pwd", password);
        input.append("password", password);
        return this.http.post<any>(USER_LOGIN_URL, input)
            .pipe(map(response => {
                // console.log("=======>" + JSON.stringify(response));
                return response;
            }));
    }
    storeUserInLocalStorage2(obj: any){
        // console.log(obj);
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(obj.user));
        this.currentUser = this.currentUserSubject.asObservable();
        this.storageService.storeItem(CURRENT_USER, obj.user);        
        this.storageService.storeItem(UNAME, obj.name);
        this.storageService.storeItem(TOKEN, obj.token);
        this.storageService.storeItem(IS_LOGGEDIN, "true");
        this.storageService.storeItem(USER_TYPE, obj.user_type);
        this.storageService.storeItem(CUNIQUE_ID, obj.cunique_id);
        this.storageService.storeItem(LINKED_ACCOUNT, String(obj.linkedAccount).toLocaleUpperCase());
        if(obj.haveSubscription) {
            this.storageService.storeItem(CURRENT_SUBSCRIPTION, JSON.stringify(obj.subscription));
        }
        this.storageService.storeItem(SESSION_EXPIRES_ON, JSON.stringify(DateUtil.getDateAfterDays(7)));
        this.storageService.storeItem('window', 'INDIVIDUAL');

        this.userEvent.emit(true);
    }

    showHeaderLinks() {
        this.userEvent.emit(true);
    }

    hideFooter(hide: boolean) {
        this.hideFooterEvent.emit(hide);
    }
    hideNavMenu(hide: boolean) {
        this.hideNavMenuEvent.emit(hide);
    }

    hideHeader(hide: boolean) {
        this.hideHeaderEvent.emit(hide);
    }

    partnerLogout(){
        this.partnerLogoutEvent.emit(true);
    }

    clearPartnerSessionData() {
        PARTNER_SESSION_KEYS.forEach((key) => { this.storageService.removeItem(key); });
    }

    clearUserSessionData() {
        USER_SESSION_KEYS.forEach((key) => { this.storageService.removeItem(key); });
    }

    logout() {
        this.storageService.clearStorage();
        // remove user from local storage and set current user to null
        // this.storageService.removeItem(CURRENT_USER);
        // this.storageService.removeItem(TOKEN);
        // this.storageService.storeItem(IS_LOGGEDIN, "false");

        if(this.currentUserSubject) this.currentUserSubject.next(null);
        this.userEvent.emit(false);
        this.userLogoutEvent.emit(true);
        // this.router.navigate(['/home']);
        // this.router.navigateByUrl('home', { skipLocationChange: true })
        //     .then(() => {
        //         this.router.navigate(['/home']);
        //     });
        
    }

    clearUser() {
        this.storageService.removeItem(CURRENT_USER);
        this.storageService.removeItem(TOKEN);
        this.storageService.storeItem(IS_LOGGEDIN, "false");
        if(this.currentUserSubject) this.currentUserSubject.next(null);
        this.userEvent.emit(false);
    }

    makeHeaderActiveEvent(courseId: number){
        this.deviceBackButtonEvent.emit(courseId);
    }

    /**Facebook login */
    fbSetup() {
        (window as any).fbAsyncInit = function() {
            FB.init({
                appId      : FACEBOOK_APP_ID,
                cookie     : true,
                xfbml      : true,
                version    : 'v3.1'
            });
            FB.AppEvents.logPageView();
        };

        // (function(d, s, id){
        //     var js, fjs = d.getElementsByTagName(s)[0];
        //     if (d.getElementById(id)) {return;}
        //     js = d.createElement(s); js.id = id;
        //     js.src = FACEBOOK_SDK_URL; //"https://connect.facebook.net/en_US/sdk.js";
        //     fjs.parentNode.insertBefore(js, fjs);
        //     }(document, 'script', 'facebook-jssdk'));
    }

    doFacebookLogin() {
        let responseObject = {
            status : false,
            fbResponseData: {}
        };
        // FB.login();
        FB.login((response: any) => {
            if (response.status === "connected") {
                //login success
                //login success code here
                //redirect to home page
                let accessToken = response.authResponse.accessToken;
                let userId = response.authResponse.userID;
                let url = FACEBOOK_USER_DETAILS_URL.replace("{fb-user-id}", userId).replace("{access-token}",accessToken);
                let fbResponse = this.getFacebookUserInfo(url);
                fbResponse.subscribe((data:any) => {
                    this.fbUserLoginEvent.emit(data);
                })        
            } else {
                console.log('User login failed');
            }
        }, {scope: 'email'});
    }

    getFacebookUserInfo(url: string): Observable<any> {
		return this.http.get<any>(url);
    }

    saveTerms(user_id: string) {
        let input = new FormData();
        input.append("user_id", user_id);
        return this.http.post<any>(PARTNER_USER_TERMS_URL, input)
            .pipe(map(response => {
                // console.log("=======>" + JSON.stringify(response));
                return response;
            }));
    }
}
