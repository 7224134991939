<div class="card">
    <div class="card-header">
        <small>Basic Profile</small>
    </div>
    <div class="card-body">
        <form [formGroup]="editBasicForm">
            <div class="form-group">
                <label for="first_name">First Name:</label>
                <input class="form-control" id="first_name" type="text" formControlName="first_name"
                    name="first_name" placeholder="First Name" />
            </div>
            <div class="form-group">
                <label for="last_name">Last Name:</label>
                <input class="form-control" id="last_name" type="text" formControlName="last_name"
                    name="last_name" placeholder="Last Name" />
            </div>
            <div class="form-group">
                <label>Gender</label><br>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" value="MALE"
                        formControlName="gender" id="inlineRadio1">
                    <label class="form-check-label" for="inlineRadio1">Male</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" value="FEMALE"
                        formControlName="gender" id="inlineRadio2">
                    <label class="form-check-label" for="inlineRadio2">Female</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" value="OTHERS"
                        formControlName="gender" id="inlineRadio3">
                    <label class="form-check-label" for="inlineRadio3">Others</label>
                </div>
            </div>
            <div class="form-group">
                <label for="dob">Date of Birth:</label>
                <input class="form-control" id="dob" type="date" formControlName="dob" name="dob"
                    placeholder="Date of Birth" format="yyyy-MM-dd" />
            </div>
            <div class="form-group">
                <label for="phone_number">Phone Number</label>
                <input class="form-control" id="phone_number" type="text" formControlName="phone_number"
                    name="phone_number" placeholder="Phone Number" />
            </div>
            <div class="form-group">
                <label for="alternate_number">Alternate Number</label>
                <input class="form-control" id="alternate_number" type="text"
                    formControlName="alternate_number" name="alternate_number"
                    placeholder="Alternate Number" />
            </div>
            <div class="form-group">
                <label for="email">Email:</label>
                <input class="form-control" id="email" type="text" formControlName="email" name="email"
                    placeholder="Email" />
            </div>
            <div class="form-group">
                <label for="last_name">Website:</label>
                <input class="form-control" id="website" type="text" formControlName="website"
                    name="website" placeholder="Website" />
            </div>
            <br />
            <button class="btn btn-primary" (click)="updateBasicProfile()">update</button>
        </form>
    </div>
</div>