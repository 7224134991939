import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service';

@Component({
  selector: 'app-cp-edit-basic',
  templateUrl: './cp-edit-basic.component.html',
  styleUrls: ['./cp-edit-basic.component.css']
})
export class CpEditBasicComponent implements OnInit {
  @Input() selectedProfile!: any;
  editBasicForm!: FormGroup;
  profileObj: any;

  constructor(public formBuilder: FormBuilder,public router: Router, public service:ApiService) { }
  ngAfterViewInit(): void {
    console.log(this.selectedProfile?.cp_profile_id);
  }
  ngOnInit(): void {
    this.initForm();
    this.editableForm();

  }

  initForm() {
    this.editBasicForm = this.formBuilder.group({
      id: '',
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      phone_number: ['', Validators.required],
      alternate_number: ['', Validators.required],
      email: ['', Validators.required],
      website: ['', Validators.required],
    });
  }
 editableForm(){
  this.service.getProfileDetails(this.selectedProfile?.cp_profile_id).subscribe((response: any) => {
    console.log(response);
    let ProfileDetails = response.data[0];
    this.profileObj = {
      "id": ProfileDetails.id,
      "first_name": ProfileDetails.first_name,
      "last_name": ProfileDetails.last_name,
      "gender": ProfileDetails.gender,
      "dob": ProfileDetails.dob,
      "phone_number": ProfileDetails.phone_number,
      "alternate_number": ProfileDetails.alternate_number,
      "email": ProfileDetails.email,
      "website": ProfileDetails.website
    }
    console.log(this.profileObj);

    this.editBasicForm.setValue(this.profileObj);
  });

 }
  updateBasicProfile(){
    const updateProfileObj = {
      "cp_profile_id": this.selectedProfile?.cp_profile_id,
      "review_type": "basic",
      "old_value": JSON.stringify(this.profileObj),
      "new_value": JSON.stringify(this.editBasicForm.value),
      "review_status": "PENDING",
      "approved_by": "",
      "approved_on": "",
    }
    console.log(updateProfileObj);
    this.service.saveCpProfileReview(updateProfileObj).subscribe(res => {
      console.log(res)
    });


  }
}
