import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CP_LOGIN_URL, GET_CP_PROFILE_DETAILS, GET_CP_PROFILE_LIST } from "../constants/ApiEndPoints";

@Injectable()
export class ProfileDataService {

    constructor(private http: HttpClient) { }

    getCpProfileList() {
      return this.http.get<any>(GET_CP_PROFILE_LIST);
    }
    getProfileDetails(profileId: string) {
      return this.http.get<any>(GET_CP_PROFILE_DETAILS.replace('{id}',profileId));
    }
    cpLogin(phone_number: string, password: string) {
      let input = new FormData();
      input.append("phone_number", phone_number);
      input.append("password", password);
      return this.http.post<any>(CP_LOGIN_URL, input);
    }
 }
