import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service';

@Component({
  selector: 'app-cp-edit-about',
  templateUrl: './cp-edit-about.component.html',
  styleUrls: ['./cp-edit-about.component.css']
})
export class CpEditAboutComponent implements OnInit {
  startTime: string = "";
  endTime: string = "";

  days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  languages: string[] = ["Telugu", "Hindi", "English", "Kannada", "Tamil", "Panjabi", "Malayalam", "Gujarati", "Marathi","Bengali","Odia","Urdu"];

  editAboutForm!: FormGroup;
  aboutObj: any;
  @Input() selectedProfile!: any;

  addedCpAvailableDayList: any[] = [];
  addedCpLanguageList: any[] = [];

  expY: number = 0;
  expM: number = 0;
  constructor(public formBuilder: FormBuilder, private service: ApiService) {}

  ngOnInit(): void {
    this.initAboutForm();
    console.log(this.selectedProfile);
  }


  initAboutForm(){
    const times = this.selectedProfile?.work_hours?.split('-');
    this.addedCpAvailableDayList = this.selectedProfile?.available_days?.split(',')  || [];
    this.addedCpLanguageList = this.selectedProfile?.languages?.split(',') || [];
    const exp = this.selectedProfile?.experience?.split('-') || [];
    if(exp && exp.length > 0) {
      this.expY = exp[0];
      this.expM = exp[1];
    }
    if(times && times.length > 0) {
      this.startTime = times[0].substr(0, times[0].length - 3).trim();
      this.endTime = times[1].substr(0, times[1].length - 3).trim();

      console.log(this.startTime, this.endTime);
      
    }

    this.editAboutForm = this.formBuilder.group({
      id: '',
      cp_profile_id:'',
      brief: [this.selectedProfile?.brief, Validators.required],
      expY: [this.expY, Validators.required],
      expM: [this.expM, Validators.required],
      fee: [this.selectedProfile?.fee, Validators.required],
      available_days: [this.selectedProfile?.available_days, Validators.required],
      startTime: [this.startTime, Validators.required],
      endTime: [this.endTime, Validators.required],
      mode: [this.selectedProfile?.mode, Validators.required],
      languages: [this.selectedProfile?.languages, Validators.required],
      education: [this.selectedProfile?.education, Validators.required],
      achievements: [this.selectedProfile?.achievements, Validators.required],
    });
  }
 editableForms(){
  this.service.getAboutDetails(this.selectedProfile?.cp_profile_id).subscribe((response: any) => {
    console.log(response.data[0]);
    let aboutDetails = response.data[0];
    this.aboutObj = {
      "id": aboutDetails.id,
      "cp_profile_id": aboutDetails.cp_profile_id,
      "brief": aboutDetails.brief,
      "experience": aboutDetails.experience,
      "fee": aboutDetails.fee,
      "available_days": aboutDetails.available_days,
      "work_hours": aboutDetails.work_hours,
      "mode": aboutDetails.mode,
      "languages": aboutDetails.languages,
      "education": aboutDetails.education,
      "achievements": aboutDetails.achievements,
    }
    
    this.editAboutForm.setValue(this.aboutObj);

  });
 }

 setTime($event: any, type: string) {
  console.log($event.target.value);
  // console.log(moment($event.target.value, 'hh:mm a').format('HH:mm'));
  if(type === 'start') this.startTime = $event.target.value + 'AM'; 
  if(type === 'end') this.endTime = $event.target.value + 'PM'; 
}

onDayChange(evt: any, day: string) {
  const status = evt.target.checked;
  console.log(evt.target.value);
  const index = this.addedCpAvailableDayList.indexOf(day);
  if (status) {
    if(index === -1) this.addedCpAvailableDayList.push(evt.target.value);
  } else {
    if(index !== -1) this.addedCpAvailableDayList.splice(index, 1);
  }
  console.log(this.addedCpAvailableDayList.toString())
}

onLanguageChange(evt: any, language: string) {
  const status = evt.target.checked;
  console.log(evt.target.value);
  const index = this.addedCpLanguageList.indexOf(language);
  if (status) {
    if(index === -1) this.addedCpLanguageList.push(evt.target.value);
  } else {
    if(index !== -1) this.addedCpLanguageList.splice(index, 1);
  }
  console.log(this.addedCpLanguageList.toString())
}

changeExp(event:any, type: string) {
  if(type === 'Y') this.expY = event.target.value;
  if(type === 'M') this.expM = event.target.value;
}

updateAbout() {
  this.editAboutForm.controls['available_days'].setValue(this.addedCpAvailableDayList.toString());
  this.editAboutForm.controls['languages'].setValue(this.addedCpLanguageList.toString());
  console.log(this.editAboutForm.value);
  const aboutmodifyObj = {
    "cp_profile_id": this.selectedProfile.cp_profile_id,
    "brief": this.editAboutForm.controls['brief'].value,
    "experience": this.expY + " " + this.expM,
    "fee": this.editAboutForm.controls['fee'].value,
    "available_days": this.editAboutForm.controls['available_days'].value,
    "work_hours": this.startTime + " - " + this.endTime,
    "mode": this.editAboutForm.controls['mode'].value,
    "languages": this.editAboutForm.controls['languages'].value,
    "education": this.editAboutForm.controls['education'].value,
    "achievements": this.editAboutForm.controls['achievements'].value,
  }

  const updateAboutObj = {
    "cp_profile_id": this.selectedProfile.cp_profile_id,
    "review_type": "about",
    "old_value": JSON.stringify(this.aboutObj),
    "new_value": JSON.stringify(aboutmodifyObj),
    "review_status": "PENDING",
    "approved_by": "",
    "approved_on": "",
  }
  this.service.saveCpAboutReview(updateAboutObj).subscribe(res => {
    console.log(res)
  });
  
}

  }


