import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service';
import moment from "moment";


@Component({
  selector: 'app-cp-review',
  templateUrl: './cp-review.component.html',
  styleUrls: ['./cp-review.component.css']
})
export class CpReviewComponent implements OnInit {
  user: any;
  address: any;
  therapyList: any;
  servicesList: any;
  specializationList: any;
  loginForm: boolean = true;
  profileForm: boolean = false;
  loginform!: FormGroup;
  loading = false;
  submitted = false;
  profileObj: any;
  addressObj: any;
  selectLanguages: any;
  selectedweeks: any;
  newProfileForm!: FormGroup;
  aboutForm!: FormGroup;
  addressForm!: FormGroup;
  specializationForm!: FormGroup;
  serviceForm!: FormGroup;
  therapyForm!: FormGroup;

  phone_number: string;
  password: string;
  errorMessage = '';
  selectedCpProfileId = 'WNkbaJ4YdzCFsuvR';
  cpServiceIds = '';
  getServiceNames:any;
  year: string = "";
  month: string = "";
  experience: string = "";
  aboutObj: any;
  available_days: string = "";
  selectedValues: any[] = [];
  Languages: string[] = [
    'English', 'Telugu', 'Hindi', 'Marathi', 'Kannada', 'Tamil', 'Assamese', 'Gujarati', 'Bengali', 'Kashmiri', 'Malayalam', 'Odia', 'Sindhi', 'Punjabi'
  ];
  Weeks: string[] = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];

  startTime: string = "";
  endTime: string = "";

  work_hours: string = ""
  aboutDetails: any;

  cpServiceList: any[] = [];
  cpTherapyList: any[] = [];
  cpSpecializationList: any[] = [];

  multiLanguages: string = "";
  selectedLanguages: any[] = [];

  multiServiceId: string = "";
  selectedServiceId: any[] = [];

  multiSpecializationId: string = "";
  selectedSpecializationId: any[] = [];

  multiTherapyId: string = "";
  selectedTherapyId: any[] = [];
  constructor(private formBuilder: FormBuilder, private service: ApiService) { }

  ngOnInit(): void {
    this.initForm();
    this.initAddressForm();
    this.initAboutForm();
    this.initTherapyForm();
    this.initSpecailizationForm();
    this.initTherapyForm();
    this.editableForms();

    // this.service.getBaseProfile().subscribe(res => {
    //   this.user = res['Profile'][0];
    // });

    this.service.getAddressDetails(this.selectedCpProfileId).subscribe(res => {
      console.log(res);
    });

    this.service.getTherapy().subscribe(res => {
      this.therapyList = res.data;
      console.log(this.therapyList);
    })
    this.service.getServices().subscribe(res => {
      this.servicesList = res?.data;
    })
    this.service.getSpecialization().subscribe(res => {
      this.specializationList = res?.data;
    })
  }

  initForm() {
    this.newProfileForm = this.formBuilder.group({
      id: '',
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      phone_number: ['', Validators.required],
      alternate_number: ['', Validators.required],
      email: ['', Validators.required],
      website: ['', Validators.required],
    });
  }

  initAboutForm() {
    // id	cp_profile_id	brief	first_degree	second_degree	proof_id	experience	fee	available_days	work_hours	mode	languages	education	achievements
    this.aboutForm = this.formBuilder.group({
      id: '',
      cp_profile_id:'',
      brief: ['', Validators.required],
      first_degree: ['', Validators.required],
      second_degree: ['', Validators.required],
      experience: ['', Validators.required],
      fee: ['', Validators.required],
      available_days: ['', Validators.required],
      work_hours: ['', Validators.required],
      mode: ['', Validators.required],
      languages: ['', Validators.required],
      education: ['', Validators.required],
      achievements: ['', Validators.required],
    });
  }

  initAddressForm() {
    this.addressForm = this.formBuilder.group({
      id: '',
      address_line_one: ['', Validators.required],
      address_line_two: ['', Validators.required],
      address_line_three: ['', Validators.required],
      city: ['', Validators.required],
      pincode: ['', Validators.required],
      country: ['', Validators.required],
    });

  }

  initTherapyForm() {
    this.therapyForm = this.formBuilder.group({
         therapy_name: ['', Validators.required]
    });

  }
  initserviceForm() {
    this.serviceForm = this.formBuilder.group({
      service_name: ['', Validators.required]
 
    });

  }
  initSpecailizationForm() {
    this.specializationForm = this.formBuilder.group({
      specialization_name: ['', Validators.required]

    });

  }

  logInUser() {
    if (this.phone_number == "123456789" && this.password == "123456789") {
      console.log("welcome to dashboard");
      this.profileForm = true;
      this.loginForm = false;
    } else {
      this.profileForm = false;
      this.loginForm = true;
      this.errorMessage = 'user unautherized';
      console.log("user unautherized");
    }

  }

  selectStartTime(e: any) {
    this.startTime = moment(e.target.value, 'hh:mm a').format('hh:mm A');
    // console.log(this.startTime);

  }
  selectEndTime(e: any) {
    this.endTime = moment(e.target.value, 'hh:mm a').format('hh:mm A');
    // console.log(this.endTime);
  }

  onChangeExperience(e: any) {
    this.year = e.target.value + " Years";
    console.log(this.year);

  }

  onChangeMonths(e: any) {
    this.month = e.target.value + " Months";
    // console.log(this.month);
  }

  onCheckboxChange(evt: any) {
    const status = evt.target.checked;
    console.log(evt.target.value);
    if (status) {
      this.selectedServiceId.push(evt.target.value);
    } else {

      this.selectedServiceId = this.selectedServiceId.filter((v: any) => v !== evt.target.value)
    }
    this.multiServiceId = this.selectedServiceId.join(',')
    console.log(this.multiServiceId)

  }
  onCheckboxTherapy(evt: any) {
    const status = evt.target.checked;
    console.log(evt.target.value);
    if (status) {
      this.selectedTherapyId.push(evt.target.value);
    } else {

      this.selectedTherapyId = this.selectedTherapyId.filter((v: any) => v !== evt.target.value)
    }
    this.multiTherapyId = this.selectedTherapyId.join(',')
    console.log(this.multiTherapyId)

  }
  onCheckboxSpecialization(evt: any) {
    const status = evt.target.checked;
    console.log(evt.target.value);
    if (status) {
      this.selectedSpecializationId.push(evt.target.value);
    } else {

      this.selectedSpecializationId = this.selectedSpecializationId.filter((v: any) => v !== evt.target.value)
    }
    this.multiSpecializationId = this.selectedSpecializationId.join(',')
    console.log(this.multiSpecializationId)

  }


  editableForms() {

    this.service.getProfileDetails(this.selectedCpProfileId).subscribe((response: any) => {

      console.log(response);
      let ProfileDetails = response.data[0];
      this.profileObj = {
        "id": ProfileDetails.id,
        "first_name": ProfileDetails.first_name,
        "last_name": ProfileDetails.last_name,
        "gender": ProfileDetails.gender,
        "dob": ProfileDetails.dob,
        "phone_number": ProfileDetails.phone_number,
        "alternate_number": ProfileDetails.alternate_number,
        "email": ProfileDetails.email,
        "website": ProfileDetails.website
      }
      console.log(this.profileObj);

      this.newProfileForm.setValue(this.profileObj);
    });

    this.service.getAboutDetails(this.selectedCpProfileId).subscribe((response: any) => {
      console.log(response.data[0]);
      let aboutDetails = response.data[0];
      this.aboutObj = {
        "id": aboutDetails.id,
        "brief": aboutDetails.brief,
        "first_degree": aboutDetails.first_degree,
        "second_degree": aboutDetails.second_degree,
        "experience": aboutDetails.experience,
        "fee": aboutDetails.fee,
        "available_days": aboutDetails.available_days,
        "work_hours": aboutDetails.work_hours,
        "mode": aboutDetails.mode,
        "languages": aboutDetails.languages,
        "education": aboutDetails.education,
        "achievements": aboutDetails.achievements,
      }
      this.aboutObj['languages'] = this.aboutObj.languages.split(',');
      this.aboutObj['available_days'] = this.aboutObj.available_days.split(',');
      this.selectLanguages = this.aboutObj.languages;
      this.selectedweeks = this.aboutObj.available_days;
      // this.uniqueLanguages = [...new Set( this.languages.map((obj:any) => obj)) ];

      console.log(this.aboutObj);
      this.aboutForm.setValue(this.aboutObj);

    });

    this.service.getAddressDetails(this.selectedCpProfileId).subscribe((response: any) => {
      console.log(response);
      let AddressDetails = response.data[0];
      this.addressObj = {
        "id": AddressDetails.id,
        "address_line_one": AddressDetails.address_line_one,
        "address_line_two": AddressDetails.address_line_two,
        "address_line_three": AddressDetails.address_line_three,
        "city": AddressDetails.city,
        "pincode": AddressDetails.pincode,
        "country": AddressDetails.country,
      }
      // this.aboutObj.controls['languages'].value 
      console.log(this.addressObj)

      this.addressForm.setValue(this.addressObj);
    });

    this.service.getcpProfileService(this.selectedCpProfileId).subscribe(response=>{
      this.cpServiceIds = response.data[0].cp_service_id.split(',');
      console.log(this.cpServiceIds);
    })

  }

  getSelection(item: any) {
    return this.selectLanguages?.findIndex((s: any) => s === item) !== -1;
  }

  getSelectionWeeks(item: any) {
    return this.selectedweeks?.findIndex((s: any) => s === item) !== -1;
  }
  selectCheckBox(evt: any, val: any) {
    const status = evt.target.checked;
    console.log(evt.target.value);
    if (status) {
      this.selectedValues.push(val)
    } else {
      this.selectedValues = this.selectedValues.filter((v: any) => v !== val)
    }
    this.available_days = this.selectedValues.join(',')
    console.log(this.available_days)
  }

  selectLanguage(evt: any, val: any) {
    const status = evt.target.checked;
    if (status) {
      this.selectedLanguages.push(val)
    } else {
      this.selectedLanguages = this.selectedLanguages.filter((v: any) => v !== val)
    }
    this.multiLanguages = this.selectedLanguages.join(',')
    console.log(this.multiLanguages)
  }
  updateBasicProfile() {
    const updateProfileObj = {
      "cp_profile_id": this.selectedCpProfileId,
      "review_type": "basic",
      "old_value": JSON.stringify(this.profileObj),
      "new_value": JSON.stringify(this.newProfileForm.value),
      "review_status": "PENDING",
      "approved_by": "",
      "approved_on": "",
    }
    this.service.saveCpProfileReview(updateProfileObj).subscribe(res => {
      console.log(res)
    });

  }
  updateAbout() {
    const aboutmodifyObj = {
      "cp_profile_id": this.selectedCpProfileId,
      "brief": this.aboutForm.controls['brief'].value,
      "experience": this.year + " " + this.month,
      "fee": this.aboutForm.controls['fee'].value,
      "available_days": this.available_days,
      "work_hours": this.startTime + " - " + this.endTime,
      "mode": this.aboutForm.controls['mode'].value,
      "languages": this.multiLanguages,
      "education": this.aboutForm.controls['education'].value,
      "achievements": this.aboutForm.controls['achievements'].value,
    }

    const updateAboutObj = {
      "cp_profile_id": this.selectedCpProfileId,
      "review_type": "about",
      "old_value": JSON.stringify(this.aboutObj),
      "new_value": JSON.stringify(aboutmodifyObj),
      "review_status": "PENDING",
      "approved_by": "",
      "approved_on": "",
    }
    this.service.saveCpAboutReview(updateAboutObj).subscribe(res => {
      console.log(res)
    });
  }

  updateAddress() {
    const updateProfileObj = {
      "cp_profile_id": this.selectedCpProfileId,
      "review_type": "address",
      "old_value": JSON.stringify(this.addressObj),
      "new_value": JSON.stringify(this.addressForm.value),
      "review_status": "PENDING",
      "approved_by": "",
      "approved_on": "",
    }
    this.service.saveCpAddressReview(updateProfileObj).subscribe(res => {
      console.log(res)
    });

  }

  updateService(){
    const oldValueObj = {
      "cp_profile_id": this.selectedCpProfileId,
      "service_id": this.cpServiceIds
    }
      console.log(oldValueObj);

    const servicemodifyObj = {
      "cp_profile_id": this.selectedCpProfileId,
      "service_id":''
    }
    const updateServiceObj = {
      "cp_profile_id": this.selectedCpProfileId,
      "review_type": "service",
      "old_value": JSON.stringify(oldValueObj),
      "new_value": JSON.stringify(servicemodifyObj),
      "review_status": "PENDING",
      "approved_by": "",
      "approved_on": "",
    }
    // this.service.saveCpServiceReview(updateServiceObj).subscribe(res => {
    //   console.log(res)
    // });
  }

  getTherapyName(id: string, i: number) {
    const name =  this.therapyList.find((therapy:any) => therapy?.therapy_id === id)?.therapy_name;  
    return name; 
  }
  getServiceName(id: string, i: number) {
    const name = this.servicesList.find((service:any) => service?.service_id === id)?.service_name;  
   this.getServiceNames = name;
   console.log(name);
    return name; 
  }

}
