import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GET_ONMOOD9_MOODS_URL, GET_CATEGORIES_BY_TYPE_URL, GET_HOME_PAGE_DIMENSIONS_URL, GET_MEDITATION_MODULES_BY_SERIES_ID_URL, GET_FEATURED_MODULES_URL, GET_MEDITATE_MODULES_BY_RELAX_ID_URL, GET_RELAX_MODULES_BY_RELAX_ID_URL, GET_TOP_VISIT_MODULES_URL, GET_CATEGORIES_WITH_GROUP_COUNT1_URL, GET_LIBRARY_VIDEO_URL, POST_SLEEP_CHECK_IN, GET_SLEEP_CHECK_IN, POST_SLEEP_CHECK_OUT, GET_SLEEP_CHECK_OUT, GET_BASE_PROFILE ,GET_ADDRESS, GET_THERAPY, GET_SERVICES, GET_SPECIALIZATION, GET_ADDRESS_BY_ID, GET_CP_PROFILE_BY_ID, SAVE_CP_PROFILE_REVIEW, SAVE_CP_ADDRESS_REVIEW, GET_ABOUT_BY_ID, SAVE_CP_ABOUT_REVIEW, SAVE_CP_SPECIALIZATION_REVIEW, SAVE_CP_THERAPY_REVIEW, SAVE_CP_SERVICE_REVIEW, GET_CP_PROFILE_SERVICE_BY_ID, CP_SEARCH_PROFILE_URL} from '../constants/ApiEndPoints';
import { MEDITATE_ID, HOME_PAGE_ID, MEDITATION_SERIES_ID, MEDITATION_SINGLE_ID, RELAX_ID, LEARN_ID } from '../constants/Constants';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  makeGetRequest(url: string) {
    return this.http.get<any>(url);
  }

  makePostRequest(url: string, body: any) {
    return this.http.post<any>(url, body);
  }

  // Onmood9 Moods
  getOnmood9Moods() {
    return this.http.get<any>(GET_ONMOOD9_MOODS_URL);
  }
  getBaseProfile() {
    return this.http.get<any>(GET_BASE_PROFILE);
  }
  getAddress() {
    return this.http.get<any>(GET_ADDRESS);
  }
  getTherapy() {
    return this.http.get<any>(GET_THERAPY);
  }
  getServices() {
    return this.http.get<any>(GET_SERVICES);
  }
  getSpecialization() {
    return this.http.get<any>(GET_SPECIALIZATION);
  }
  getMeditateCourses() {
    let url = GET_CATEGORIES_BY_TYPE_URL.replace('{id}', "" + MEDITATE_ID);
    return this.http.get<any>(url);
  }

  getHomePageDimensions() {
    let url = GET_HOME_PAGE_DIMENSIONS_URL;
    let input = new FormData();
    input.append("page_id", "" + HOME_PAGE_ID);
    return this.http.post<any>(url, input);
  }


  getMeditationSeriesModules2() {
    let input = new FormData();
    input.append("id", "" + MEDITATION_SERIES_ID);
    return this.http.post<any>(GET_MEDITATION_MODULES_BY_SERIES_ID_URL, input);
  }

  getMeditationSingleModules2() {
    let input = new FormData();
    input.append("id", "" + MEDITATION_SINGLE_ID);
    return this.http.post<any>(GET_MEDITATION_MODULES_BY_SERIES_ID_URL, input);
  }

  getFeaturedModules() {
    return this.http.get<any>(GET_FEATURED_MODULES_URL);
  }

  getMeditationModules() {
    let input = new FormData();
    input.append("id", "" + MEDITATE_ID);
    return this.http.post<any>(GET_MEDITATE_MODULES_BY_RELAX_ID_URL, input);
  }

  getRelaxMusicModules() {
    let input = new FormData();
    input.append("id", "" + RELAX_ID);
    return this.http.post<any>(GET_RELAX_MODULES_BY_RELAX_ID_URL, input);
  }

  getLearnCourses() {
    let url = GET_CATEGORIES_BY_TYPE_URL.replace('{id}', "" + LEARN_ID);
    return this.http.get<any>(url);
  }
  getProfileDetails(profileId: string) {
    let url = GET_CP_PROFILE_BY_ID.replace('{id}',profileId);
    return this.http.get<any>(url);
  }
  getAddressDetails(profileId: string) {
    let url = GET_ADDRESS_BY_ID.replace('{id}',profileId);
    return this.http.get<any>(url);
  }
  getAboutDetails(profileId: string) {
    let url = GET_ABOUT_BY_ID.replace('{id}',profileId);
    return this.http.get<any>(url);
  }
  getcpProfileService(profileId: string) {
    let url = GET_CP_PROFILE_SERVICE_BY_ID.replace('{id}',profileId);
    return this.http.get<any>(url);
  }


  getMeditationSeriesModules(dimensions: any) {
    let input = new FormData();
    input.append("id", "" + MEDITATION_SERIES_ID);
    input.append("newWidth", dimensions.width);
    input.append("newHeight", dimensions.height);
    return this.http.post<any>(GET_MEDITATION_MODULES_BY_SERIES_ID_URL, input);
  }

  getMeditationSingleModules(dimensions: any) {
    let input = new FormData();
    input.append("id", "" + MEDITATION_SINGLE_ID);
    input.append("newWidth", dimensions.width);
    input.append("newHeight", dimensions.height);
    return this.http.post<any>(GET_MEDITATION_MODULES_BY_SERIES_ID_URL, input);
  }

  getRelaxCourses() {
    return this.http.get<any>(GET_CATEGORIES_BY_TYPE_URL.replace('{id}', "" + RELAX_ID));
  }

  getTopVisitModules(categoryId: number) {
    return this.http.get<any>(GET_TOP_VISIT_MODULES_URL.replace('{category_id}', "" + categoryId));
  }


  getAllCategoriesWithGroupCount1(categoryId: number) {
    return this.http.get<any>(GET_CATEGORIES_WITH_GROUP_COUNT1_URL.replace('{id}', "" + categoryId));
  }

  // Onmood9 Videos
  getVideoById(id: number) {
    return this.http.get<any>(GET_LIBRARY_VIDEO_URL.replace("{id}", "" + id));
  }

  saveSleepCheckIn(payload: any) {
    return this.http.post<any>(POST_SLEEP_CHECK_IN, payload);
  }

  getSleepCheckInData(payload: any) {
    return this.http.get<any>(`${GET_SLEEP_CHECK_IN}/${payload.userId}/${payload.fromDate}/${payload.toDate}`);
  }

  saveSleepCheckOut(payload: any) {
    return this.http.post<any>(POST_SLEEP_CHECK_OUT, payload);
  }
  saveCpProfileReview(payload: any) {
    return this.http.post<any>(SAVE_CP_PROFILE_REVIEW, payload);
  }
  saveCpAddressReview(payload: any) {
    return this.http.post<any>(SAVE_CP_ADDRESS_REVIEW, payload);
  }
  saveCpServiceReview(payload: any) {
    return this.http.post<any>(SAVE_CP_SERVICE_REVIEW, payload);
  }
  saveCpTherapyReview(payload: any) {
    return this.http.post<any>(SAVE_CP_THERAPY_REVIEW, payload);
  }
  saveCpSpecializationReview(payload: any) {
    return this.http.post<any>(SAVE_CP_SPECIALIZATION_REVIEW, payload);
  }
  saveCpAboutReview(payload: any) {
    return this.http.post<any>(SAVE_CP_ABOUT_REVIEW, payload);
  }
  getSleepCheckOutData(payload: any) {
    return this.http.get<any>(`${GET_SLEEP_CHECK_OUT}/${payload.userId}/${payload.fromDate}/${payload.toDate}`);
  }
  searchProfile(payload: any) {
    return this.http.post<any>(CP_SEARCH_PROFILE_URL, payload);
  }
}