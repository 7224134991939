import { environment } from "../../environments/environment";
export const UI_WEBSITE_URL = "https://mood9.com/#/";

export const BASE_URL = environment.apiUrl;


// export const ONMOOD9_IMAGES_PATH = WEBSITE_URL + "assets/media/";
export const ONMOOD9_IMAGES_PATH =environment.MEDIA_PATH  + "assets/media/";

export const CP_IMAGE_PATH = environment.CP_IMAGE_PATH + "uploads/care-provider/";
export const ONMOOD9_BG_MUSIC_PATH = environment.MEDIA_PATH + "uploads/meditation/";
export const ONMOOD9_COURSE_IMAGES_PATH = environment.MEDIA_PATH + "uploads/courses/";
export const ONMOOD9_SESSION_FILE_PATH = environment.MEDIA_PATH + "uploads/courses/audio/";
export const ONMOOD9_VIDEOS_PATH = environment.MEDIA_PATH + "uploads/videos/";
export const BLOG_IMAGES_PATH = environment.MEDIA_PATH + "uploads/blogs/";
export const COURSE_MUSIC_IMAGES_PATH = environment.MEDIA_PATH + "uploads/course-music/";
export const COURSES_PATH = environment.MEDIA_PATH + "uploads/courses/";

export const COURCE_TYPES_URL = BASE_URL + "CourseTypes";
export const COURCE_TYPE_DETAILS_URL = BASE_URL + "CourseTypes/{id}";
export const CREATE_COURCE_TYPE_URL = BASE_URL + "CourseTypes";
export const UPDATE_COURCE_TYPE_URL = BASE_URL + "CourseTypes";
export const DELETE_COURCE_TYPE_YRL = BASE_URL + "CourseTypes/{id}";

export const ONMOOD_COURSE_TYPES_URL = BASE_URL + "OnmoodCourseTypes";
export const ONMOOD_COURSE_TYPE_DETAILS_URL = BASE_URL + "OnmoodCourseTypes/{id}";
export const CREATE_ONMOOD_COURSE_TYPE_URL = BASE_URL + "OnmoodCourseTypes";
export const UPDATE_ONMOOD_COURSE_TYPE_URL = BASE_URL + "OnmoodCourseTypes";
export const DELETE_ONMOOD_COURSE_TYPE_YRL = BASE_URL + "OnmoodCourseTypes/{id}";

export const GET_CATEGORIES_URL = BASE_URL + "Categories";
export const GET_CATEGORY_DETAILS_URL = BASE_URL + "Categories/{id}";
export const CREATE_CATEGORY_URL = BASE_URL + "Categories";
export const UPDATE_CATEGORY_URL = BASE_URL + "Categories";
export const DELETE_CATEGORY_URL = BASE_URL + "Categories/{id}";
export const GET_CATEGORIES_WITH_GROUP_COUNT1_URL = BASE_URL + "Categories/getCategoriesWithGroupCount1/{id}";
export const GET_CATEGORIES_BY_TYPE_URL = BASE_URL + "Categories/getCategoriesByCategoryTypeId/{id}";

export const UPDATE_CATEGORY_ACTIVE_MODULE_URL = BASE_URL + "Categories/updateActiveModule";
export const GET_RELAX_MODULES_BY_RELAX_ID_URL = BASE_URL + "CategoryGroupModule/getRelaxModules";
export const GET_MEDITATE_MODULES_BY_RELAX_ID_URL = BASE_URL + "CategoryGroupModule/getMeditateModules";
export const GET_FEATURED_MODULES_URL = BASE_URL + "CategoryGroupModule/getFeaturedModules";




export const GET_CATEGORY_GROUP_URL = BASE_URL + "CategoryGroup";
export const GET_CATEGORY_GROUP_DETAILS_URL = BASE_URL + "CategoryGroup/{id}";
export const CREATE_CATEGORY_GROUP_URL = BASE_URL + "CategoryGroup";
export const UPDATE_CATEGORY_GROUP_URL = BASE_URL + "CategoryGroup/updateCategoryGroup";
export const DELETE_CATEGORY_GROUP_URL = BASE_URL + "CategoryGroup/{id}";
export const GET_CATEGORY_GROUPS_BY_CATEGORY_URL = BASE_URL + "CategoryGroup/getGroupsByCategoryId/{id}";
export const GET_CATEGORY_GROUPS_WITH_MODULE_COUNT_URL = BASE_URL + "CategoryGroup/getGroupsWithModuleCount/{id}";
export const GET_CATEGORY_GROUPS_WITH_MODULES_URL = BASE_URL + "CategoryGroup/getGroupsWithModules/{id}";


/*-------------Course group modules---------------*/
export const GET_CATEGORY_GROUP_MODULES_URL = BASE_URL + "CategoryGroupModule";
export const GET_CATEGORY_GROUP_MODULE_DETAILS_URL = BASE_URL + "CategoryGroupModule/{id}";
export const CREATE_CATEGORY_GROUP_MODULE_URL = BASE_URL + "CategoryGroupModule";
export const UPDATE_CATEGORY_GROUP_MODULE_URL = BASE_URL + "CategoryGroupModule/updateCategoryGroupModule";
export const DELETE_CATEGORY_GROUP_MODULE_URL = BASE_URL + "CategoryGroupModule/{id}";
export const GET_CATEGORY_GROUP_MODULES_BY_GROUP_ID_URL = BASE_URL + "CategoryGroupModule/getModulesByGroupId/{id}";
export const GET_CATEGORY_GROUP_MODULES_WITH_SESSION_COUNT_URL = BASE_URL + "CategoryGroupModule/getModulesWithSessionCount/{id}";
export const CREATE_CATEGORY_GROUP_MODULE_VIDEO_URL = BASE_URL + "CategoryGroupModule/addModuleVideo";
export const GET_CATEGORY_GROUP_MODULE_VIDEOS_URL = BASE_URL + "CategoryGroupModule/getModuleVideos/{module_id}";
export const GET_CATEGORY_GROUP_MODULE_AVAILABLE_VIDEOS_URL = BASE_URL + "CategoryGroupModule/getModuleAvailableVideos/{module_id}";
export const DELETE_CATEGORY_GROUP_MODULE_VIDEO_URL = BASE_URL + "CategoryGroupModule/deleteModuleVideo/{video_id}";
export const GET_TOP_VISIT_MODULES_URL = BASE_URL + "CategoryGroupModule/getTopVisitModules/{category_id}";
export const GET_MEDITATION_MODULES_BY_SERIES_ID_URL = BASE_URL + "CategoryGroupModule/getMeditationModulesBySeriesId";
export const GET_FAVOURITE_MODULES_URL = BASE_URL + "CategoryGroupModule/getUserFavouriteModules/<user_id>";
export const SAVE_FAVOURITE_MODULE_URL = BASE_URL + "CategoryGroupModule/saveUserFavouriteModule";
export const CHECK_MODULE_IS_FAVOURITE_URL = BASE_URL + "CategoryGroupModule/checkModuleIsFavourite/<user_id>/<module_id>";
export const DELETE_FAVOURITE_MODULE_URL = BASE_URL + "CategoryGroupModule/removeFavouriteModule/<ufm_id>";



/*-------------Course group module sessions---------------*/
export const GET_CATEGORY_GROUP_MODULE_SESSIONS_URL = BASE_URL + "CategoryGroupModuleSession";
export const GET_CATEGORY_GROUP_MODULE_SESSION_DETAILS_URL = BASE_URL + "CategoryGroupModuleSession/{id}";
export const CREATE_CATEGORY_GROUP_MODULE_SESSION_URL = BASE_URL + "CategoryGroupModuleSession";
export const UPDATE_CATEGORY_GROUP_MODULE_SESSION_URL = BASE_URL + "CategoryGroupModuleSession/updateCategoryGroupModuleSession";
export const DELETE_CATEGORY_GROUP_MODULE_SESSION_URL = BASE_URL + "CategoryGroupModuleSession/{id}";
export const GET_CATEGORY_GROUP_MODULE_SESSIONS_BY_MODULE_ID_URL = BASE_URL + "CategoryGroupModuleSession/getSessionsByModuleId/{id}";

export const GET_ONMOOD9_MOODS_URL = BASE_URL + "Onmood9Moods/getMoods";
export const GET_ONMOOD9_MOOD_DETAILS_URL = BASE_URL + "Onmood9Moods/{id}";
export const CREATE_ONMOOD9_MOOD_URL = BASE_URL + "Onmood9Moods";
export const UPDATE_ONMOOD9_MOOD_URL = BASE_URL + "Onmood9Moods";
export const DELETE_ONMOOD9_MOOD_URL = BASE_URL + "Onmood9Moods/{id}";

export const GET_COURCES_URL = BASE_URL + "Courses";
export const GET_COURCE_DETAILS_URL = BASE_URL + "Courses/{id}";
export const CREATE_COURCE_URL = BASE_URL + "Courses";
export const UPDATE_COURCE_URL = BASE_URL + "Courses";
export const DELETE_COURCE_URL = BASE_URL + "Courses/{id}";

export const GET_ALL_MODULES_URL = BASE_URL + "CourseModules";
export const GET_COURCE_MODULES_URL = BASE_URL + "CourseModules/course/{courseId}";
export const GET_COURCE_MODULE_DETAILS_URL = BASE_URL + "CourseModules/{id}";
export const CREATE_COURCE_MODULE_URL = BASE_URL + "CourseModules";
export const UPDATE_COURCE_MODULE_URL = BASE_URL + "CourseModules";
export const DELETE_COURCE_MODULE_URL = BASE_URL + "CourseModules/{id}";

export const GET_MODULE_SESSIONS_URL = BASE_URL + "ModuleSessions";
export const GET_MODULE_SESSION_DETAILS_URL = BASE_URL + "ModuleSessions/{id}";
export const CREATE_MODULE_SESSION_URL = BASE_URL + "ModuleSessions";
export const UPDATE_MODULE_SESSION_URL = BASE_URL + "ModuleSessions";
export const DELETE_MODULE_SESSION_URL = BASE_URL + "ModuleSessions/{id}";
export const GET_VISITED_MODULES = BASE_URL + "OnmoodUsers/getUserVisitedModules/";
export const GET_VISITED_CATEGORIES = BASE_URL + "OnmoodUsers/getUserVisitedCategories/";
export const GET_PLAYED_SESSION_DATE_RANGE = BASE_URL + "OnmoodUsers/getUserPlayedSessionsWithDateRange";
export const GET_ALL_CAT_PLAYED_SESSION_DATE_RANGE = BASE_URL + "OnmoodUsers/getUserPlayedSessionsWithDateRangeInAllCategories";
export const GET_USER_LAST_PLAYED_MODULE = BASE_URL + "OnmoodUsers/getUserLastPlayedModule";
export const GET_USER_SUBSCRIPTIONS = BASE_URL + "OnmoodUsers/getUserSubscriptions";


export const GET_BLOGS_URL = BASE_URL + "Blogs";
export const GET_ACTIVE_BLOGS_URL = BASE_URL + "Blogs/activeBlogs";
export const GET_NEXT_ACTIVE_BLOGS_URL = BASE_URL + "Blogs/nextActiveBlogs/{publish_date}";

export const GET_CATEGORY_BLOGS_URL = BASE_URL + "Blogs/categoryBlogs/{id}";
export const GET_VIDEO_BLOGS_URL = BASE_URL + "Blogs/videoBlogs";
export const GET_TEXTUAL_BLOGS_URL = BASE_URL + "Blogs/textualBlogs";
export const GET_SEARCH_BLOGS_URL = BASE_URL + "Blogs/searchBlogs";
export const GET_TAG_BASED_BLOGS_URL = BASE_URL + "Blogs/tagBasedBlogs";
export const GET_BLOG_DETAILS_URL = BASE_URL + "Blogs/{id}";
export const CREATE_BLOG_URL = BASE_URL + "Blogs";
export const UPDATE_BLOG_URL = BASE_URL + "Blogs";
export const DELETE_BLOG_URL = BASE_URL + "Blogs/{id}";
export const UPDATE_BLOG_COUNT_URL = BASE_URL + "Blogs/updateViewCount";
export const GET_MOST_VIEWED_ACTIVE_BLOGS_URL = BASE_URL + "Blogs/mostViewActiveBlogs";

export const GET_PAGE_META_URL = BASE_URL + "PageMeta";
export const GET_PAGE_META_DETAILS_URL = BASE_URL + "PageMeta/getPageMetaByName/{name}";
export const CREATE_PAGE_META_URL = BASE_URL + "PageMeta";
export const UPDATE_PAGE_META_URL = BASE_URL + "PageMeta/updatePageMeta";
export const DELETE_PAGE_META_URL = BASE_URL + "PageMeta/{id}";

export const GET_BACKGROUND_MUSICS_URL = BASE_URL + "BgMusic";
export const GET_ACTIVE_BACKGROUND_MUSICS_URL = BASE_URL + "BgMusic/getActive";
export const GET_AVAILABLE_BACKGROUND_MUSICS_URL = BASE_URL + "BgMusic/getAvailableMusicsForCourse";
export const CREATE_BACKGROUND_MUSIC_URL = BASE_URL + "BgMusic";
export const UPDATE_BACKGROUND_MUSIC_URL = BASE_URL + "BgMusic";
export const DELETE_BACKGROUND_MUSIC_URL = BASE_URL + "BgMusic/{id}";
export const UPDATE_BACKGROUND_MUSIC_STATUS_URL = BASE_URL + "BgMusic/updateStatus";

export const GET_MUSIC_SINGLES_URL = BASE_URL + "CourseMusicSingle";
export const GET_ACTIVE_MUSIC_SINGLES_URL = BASE_URL + "CourseMusicSingle/getActive";
export const CREATE_MUSIC_SINGLE_URL = BASE_URL + "CourseMusicSingle";
export const UPDATE_MUSIC_SINGLE_URL = BASE_URL + "CourseMusicSingle";
export const DELETE_MUSIC_SINGLE_URL = BASE_URL + "CourseMusicSingle/{id}";
export const UPDATE_MUSIC_SINGLE_STATUS_URL = BASE_URL + "CourseMusicSingle/updateStatus";

export const GET_LIBRARY_VIDEOS_URL = BASE_URL + "OnmoodLibrary/getVideos";
export const GET_LIBRARY_VIDEO_URL = BASE_URL + "OnmoodLibrary/getVideo/{id}";

// v=spf1 +a +mx +ip4:162.144.47.85 ~all;google-site-verification=Yk-3IIXTFJNpG2mnDcWQozEmsVDlsCQY52ykk3h0__E



export const CREATE_CATEGORY_GROUP_MODULE_SESSION_FILE_URL = BASE_URL + "CategoryGroupModuleSessionFile";
export const GET_CATEGORY_GROUP_MODULE_SESSION_FILES_BY_SESSION_ID_URL = BASE_URL + "CategoryGroupModuleSessionFile/getFilesBySessionId/{id}";
export const GET_CATEGORY_GROUP_MODULE_MALE_VOICE_SESSION_FILES_BY_SESSION_ID_URL = BASE_URL + "CategoryGroupModuleSessionFile/getMaleVoiceFilesBySessionId/{id}";
export const GET_CATEGORY_GROUP_MODULE_FEMALE_VOICE_SESSION_FILES_BY_SESSION_ID_URL = BASE_URL + "CategoryGroupModuleSessionFile/getFemaleVoiceFilesBySessionId/{id}";
export const GET_CATEGORY_GROUP_MODULE_AUDIO_SESSION_FILES_BY_SESSION_ID_URL = BASE_URL + "CategoryGroupModuleSessionFile/getAudioFilesBySessionId/{id}";


export const DELETE_CATEGORY_GROUP_MODULE_SESSION_FILE_URL = BASE_URL + "CategoryGroupModuleSessionFile/removeFile";



export const SAVE_SOCIAL_MEDIA_USER_URL = BASE_URL + "OnmoodSocialUser/addSocialMediaUser";

export const USER_LOGIN_URL = BASE_URL + "OnmoodUsersLogin/login";
export const SET_USER_PASSWORD_URL = BASE_URL + "OnmoodUsersLogin/setUserPassword";
export const NEW_USER_SIGN_UP = BASE_URL + "OnmoodUsersLogin/user";
export const PARTNER_USER_LOGIN_URL = BASE_URL + "OnmoodPartnerLogin/doUserLogin";
export const PARTNER_SUB_USER_LOGIN_URL = BASE_URL + "OnmoodPartnerLogin/doSubCorporateUserLogin";

export const PARTNER_VERIFY_ENCRYPTION_URL = BASE_URL + "OnmoodPartnerLogin/verifyKey";
export const PARTNER_DECRYPT_URL = BASE_URL + "OnmoodPartnerLogin/decrypt";
export const PARTNER_USER_TERMS_URL = BASE_URL + "OnmoodPartnerLogin/acceptTerms";




export const GET_USERS_URL = BASE_URL + "OnmoodUsers";
export const LOGIN_USER = BASE_URL + "OnmoodUsers/login";
// export const NEW_USER_SIGN_UP =                BASE_URL + "OnmoodUsers/user";
export const SOCIAL_USER_SIGN_UP = BASE_URL + "OnmoodUsers/social_user";
export const CHECK_USER_EXIST_URL = BASE_URL + "OnmoodUsers/check_user_exist";
export const CHECK_SOCIAL_USER_EXIST_URL = BASE_URL + "OnmoodUsers/check_social_user_exist";
export const SAVE_SOCIAL_USER_URL = BASE_URL + "OnmoodUsers/save_social_user";
export const UPDATE_SOCIAL_USER_PASSWORD_URL = BASE_URL + "OnmoodUsers/update_social_user_password";
export const GET_USER_PLAYED_SESSIONS_URL = BASE_URL + "OnmoodUsers/get_user_played_sessions/{userId}";
export const SAVE_USER_PLAYED_SESSION_URL = BASE_URL + "OnmoodUsers/saveUserPlayedSession";
export const CHANGE_USER_PASSWORD_URL = BASE_URL + "OnmoodUsers/changeUserPassword";



export const RESET_USER_PASSWORD_URL = BASE_URL + "OnmoodUsers/updateUserPassword";
export const UPDATE_USER_PERSONAL_INFO_URL = BASE_URL + "OnmoodUsers/updatePersonalInfo";


export const GET_USER_NOTIFICATIONS_URL = BASE_URL + "UserNotification/getUserNotifications/{userId}";


export const PASSWORD_RESET_CODE_URL = BASE_URL + "UserPasswordReset/save_key_code";
export const VERIFY_RESET_CODE_URL = BASE_URL + "UserPasswordReset/verify_key_code";
export const IS_RESET_CODE_VERIFIED_URL = BASE_URL + "UserPasswordReset/is_reset_code_verified";

export const VERIFY_USER_ACCOUNT_URL = BASE_URL + "UserPasswordReset/is_account_reset_code_verified";


export const GET_IMAGE_PAGES_URL = BASE_URL + "ImageController/getPagesForImages";
export const GET_HOME_PAGE_DIMENSIONS_URL = BASE_URL + "ImageController/getImagesByPageId";

export const GET_ATTENDED_ASSESSMENTS_BY_ASSESSMENT = BASE_URL + "v2/ui/UAssessment/getUserAttemptedAssessmentsByAssessmentId/";
export const GET_ALL_ASSESSMENTS = BASE_URL + "v2/ui/UAssessment/getAllAssessments/"
export const POST_ASSESSMENT_TEST = BASE_URL + "v2/ui/UAssessmentTrack/save_assessment_with_options"
export const POST_MOODS = BASE_URL + "UserMoodTracker/saveUserMood"
// export const GET_MOODS = BASE_URL + "UserMoodTracker/getUserMoodTrackerData";
export const GET_THOUGHTS = BASE_URL + "UserMoodTracker/getUserThoughtTrackerData";
export const POST_USER_MOODS_DATA = BASE_URL + "UserMoodTracker/getUserMoodTrackerData"
export const SECURITY_API_TEST_URL = BASE_URL + "TestController";


export const GET_CORP_SUBSCRIPTIONS_URL = BASE_URL + "UserSubscriptions/get_corporate_active_subscription/";

export const POST_SLEEP_CHECK_IN = BASE_URL + "SleepCheckIn/saveUserSleepCheckInData";
export const GET_SLEEP_CHECK_IN = BASE_URL + "SleepCheckIn/getUserSleepCheckIn";

export const POST_SLEEP_CHECK_OUT = BASE_URL + "SleepCheckOut/saveUserSleepCheckOutData";
export const GET_SLEEP_CHECK_OUT = BASE_URL + "SleepCheckOut/getUserSleepCheckOut";


export const GET_BASE_PROFILE = BASE_URL + "BasicProfile";
export const GET_ADDRESS = BASE_URL + "Address";

export const GET_ADDRESS_BY_ID = BASE_URL + "OnmoodCpAddress/getCpByAddressDetails/{id}";
export const GET_ABOUT_BY_ID = BASE_URL + "OnmoodCpAbout/getCpByAboutDetails/{id}";
export const GET_CP_PROFILE_BY_ID = BASE_URL + "OnmoodCpProfile/getCpByProfileId/{id}";
export const GET_CP_PROFILE_SERVICE_BY_ID = BASE_URL + "OnmoodCpProfileService/getCpByServiceDetails/{id}";
export const UPDATE_CP_PROFILE_SERVICE = BASE_URL + "OnmoodCpProfileService/updateCpProfileService";




export const GET_THERAPY = BASE_URL + "OnmoodCpTherapy";
export const GET_SERVICES = BASE_URL + "OnmoodCpService";
export const GET_SPECIALIZATION = BASE_URL + "OnmoodCpSpecialization";
export const GET_CP_PROFILE_LIST = BASE_URL + "DoctorProfile/getAllActiveCpProfileList";
export const GET_CP_PROFILE_DETAILS = BASE_URL + "DoctorProfile/getCpByProfileDetails/{id}";

export const SAVE_CP_PROFILE_REVIEW = BASE_URL + "OnmoodCpProfileReview/saveCpProfileReview";
export const SAVE_CP_ADDRESS_REVIEW = BASE_URL + "OnmoodCpProfileReview/saveCpProfileReview";
export const SAVE_CP_ABOUT_REVIEW = BASE_URL + "OnmoodCpProfileReview/saveCpProfileReview";

export const SAVE_CP_SERVICE_REVIEW = BASE_URL + "OnmoodCpProfileReview/saveCpProfileReview";
export const SAVE_CP_THERAPY_REVIEW = BASE_URL + "OnmoodCpProfileReview/saveCpProfileReview";
export const SAVE_CP_SPECIALIZATION_REVIEW = BASE_URL + "OnmoodCpProfileReview/saveCpProfileReview";

export const CP_LOGIN_URL = BASE_URL + "OnmoodCpBasicProfile/cpLogin";
export const CP_SEARCH_PROFILE_URL = BASE_URL + "DoctorProfile/searchProfile";


