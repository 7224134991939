<div class="container">
    <div class="row mt-5">
        <div class="spContainer mx-auto">
            <div class="card px-4 py-5 border-0 shadow">
                <div class="d-inline text-left mb-3">
                    <h3 class="font-weight-bold">Login</h3>
                    <p class="error">{{errorMessage}}</p>
                </div>
                <form>
                    <div class="d-inline text-center mb-0">
                        <div class="form-group mx-auto">
                            <label style="margin-right: 181px;">Phone Number</label>
                            <input class="form-control inpSp" type="text" [(ngModel)]="phone_number" name="phone_number"
                                placeholder="phone Number" requird>
                        </div>
                    </div>
                    <div class="d-inline text-center mb-3">
                        <div class="form-group mx-auto">
                            <label style="margin-right: 220px;">Password</label>
                            <input class="form-control inpSp" type="password" [(ngModel)]="password" name="password"
                                placeholder="Password" required>
                        </div>
                    </div>
                    <div class="d-inline text-left mb-3">
                        <div class="form-group mx-auto">
                            <button class="btn btn-primary" (click)="logInUser()">Login</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>