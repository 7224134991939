<!-- <app-login-form></app-login-form> -->

<div class="container" *ngIf="loginForm">
    <div class="row mt-5">
        <div class="spContainer mx-auto">
            <div class="card px-4 py-5 border-0 shadow">
                <div class="d-inline text-left mb-3">
                    <h3 class="font-weight-bold">Login</h3>
                    <p class="error">{{errorMessage}}</p>
                </div>
                <form>
                    <div class="d-inline text-center mb-0">
                        <div class="form-group mx-auto">
                            <label style="margin-right: 181px;">Phone Number</label>
                            <input class="form-control inpSp" type="text" [(ngModel)]="phone_number" name="phone_number"
                                placeholder="phone Number" requird>
                        </div>
                    </div>
                    <div class="d-inline text-center mb-3">
                        <div class="form-group mx-auto">
                            <label style="margin-right: 220px;">Password</label>
                            <input class="form-control inpSp" type="password" [(ngModel)]="password" name="password"
                                placeholder="Password" required>
                        </div>
                    </div>
                    <div class="d-inline text-left mb-3">
                        <div class="form-group mx-auto">
                            <button class="btn btn-primary" (click)="logInUser()">Login</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="profileForm">
    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <small>Basic Profile</small>
                </div>
                <div class="card-body">
                    <form [formGroup]="newProfileForm">
                        <div class="form-group">
                            <label for="first_name">First Name:</label>
                            <input class="form-control" id="first_name" type="text" formControlName="first_name"
                                name="first_name" placeholder="First Name" />
                        </div>
                        <div class="form-group">
                            <label for="last_name">Last Name:</label>
                            <input class="form-control" id="last_name" type="text" formControlName="last_name"
                                name="last_name" placeholder="Last Name" />
                        </div>
                        <div class="form-group">
                            <label>Gender</label><br>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="gender" value="MALE"
                                    formControlName="gender" id="inlineRadio1">
                                <label class="form-check-label" for="inlineRadio1">Male</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="gender" value="FEMALE"
                                    formControlName="gender" id="inlineRadio2">
                                <label class="form-check-label" for="inlineRadio2">Female</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="gender" value="OTHERS"
                                    formControlName="gender" id="inlineRadio3">
                                <label class="form-check-label" for="inlineRadio3">Others</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="dob">Date of Birth:</label>
                            <input class="form-control" id="dob" type="date" formControlName="dob" name="dob"
                                placeholder="Date of Birth" format="yyyy-MM-dd" />
                        </div>
                        <div class="form-group">
                            <label for="phone_number">Phone Number</label>
                            <input class="form-control" id="phone_number" type="text" formControlName="phone_number"
                                name="phone_number" placeholder="Phone Number" />
                        </div>
                        <div class="form-group">
                            <label for="alternate_number">Alternate Number</label>
                            <input class="form-control" id="alternate_number" type="text"
                                formControlName="alternate_number" name="alternate_number"
                                placeholder="Alternate Number" />
                        </div>
                        <div class="form-group">
                            <label for="email">Email:</label>
                            <input class="form-control" id="email" type="text" formControlName="email" name="email"
                                placeholder="Email" />
                        </div>
                        <div class="form-group">
                            <label for="last_name">Website:</label>
                            <input class="form-control" id="website" type="text" formControlName="website"
                                name="website" placeholder="Website" />
                        </div>
                        <br />
                        <button class="btn btn-primary" (click)="updateBasicProfile()">update</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <small>About</small>
                </div>
                <div class="card-body">
                    <form [formGroup]="aboutForm">
                        <div class="form-group">
                            <label for="brief">Brief:</label>
                            <textarea class="form-control" id="brief" type="text" formControlName="brief" name="brief"
                                placeholder="brief"></textarea>
                        </div>
                        <div class="form-group">
                            <label for="experience">Experience: {{aboutObj?.experience}}</label>
                            <div class="input-group mb-3">
                                <input type="number" class="form-control" formControlName="experience" name="experience"
                                    id="inputGroupFile02" (change)="onChangeExperience($event)">
                                <label class="input-group-text" for="inputGroupFile02">In Years</label>
                            </div>
                            <div class="input-group mb-3">
                                <input type="number" class="form-control" formControlName="experience" name="experience"
                                    id="inputGroupFile02" (change)="onChangeMonths($event)">
                                <label class="input-group-text" for="inputGroupFile02">In Months</label>
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="fee">Fee: </label>
                            <input class="form-control" id="fee" type="text" formControlName="fee" name="fee"
                                placeholder="fee" />
                            <!-- <div class="form-control">
                                <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
                            </div> -->
                        </div>
                        <div class="form-group">
                            <label for="available_days">Available Days:</label>
                            <div *ngFor="let w of Weeks; let i=index" class="col-md-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="available_days" (change)="selectCheckBox($event,w)"[checked]="getSelectionWeeks(w)" [value]="w" id="{{w}}">
                                    <label class="form-check-label" for="{{w}}">
                                        {{w}}
                                    </label>
                                  </div>
                               
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label for="available_days">Available Days:- </label>
                            <input class="form-control" id="available_days" type="text" formControlName="available_days" name="available_days"
                                placeholder="available_days" />
                            <div class="form-control">
                                <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
                            </div>
                        </div> -->
                        <div class="form-group">
                            <label for="work_hours">Work Hours:- {{aboutObj?.work_hours}}</label><br>
                            <label for="work_hours">Start Time:</label>
                            <input class="form-control" id="work_hours" type="time" formControlName="work_hours"
                                name="work_hours" placeholder="work_hours" (change)="selectStartTime($event)" />
                            <label for="work_hours">End Time:</label>
                            <input class="form-control" id="work_hours" type="time" formControlName="work_hours"
                                name="work_hours" placeholder="work_hours" (change)="selectEndTime($event)" />
                            <!-- <div class="form-control">
                                <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
                            </div> -->
                        </div>

                        <div class="form-group">
                            <label>Mode</label><br>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="mode" value="ONLINE"
                                    formControlName="mode" id="inlineRadio4">
                                <label class="form-check-label" for="inlineRadio4">Online</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="mode" value="OFFLINE"
                                    formControlName="mode" id="inlineRadio5">
                                <label class="form-check-label" for="inlineRadio5">Offline</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="mode" value="BOTH"
                                    formControlName="mode" id="inlineRadio6">
                                <label class="form-check-label" for="inlineRadio6">Both</label>
                            </div>
                        </div>


                        <div class="form-group">
                            <label for="languages">Languages:</label>
                            <div *ngFor="let lang of Languages; let i=index" class="col-md-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" (change)="selectLanguage($event,lang)"  [checked]="getSelection(lang)" [value]="lang" id="{{lang}}">
                                    <label class="form-check-label" for="{{lang}}">
                                        {{lang}}
                                    </label>
                                  </div>
                               
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="education">Education:</label>
                            <textarea class="form-control" id="education" type="text" formControlName="education"
                                name="education" placeholder="education"></textarea>
                        </div>
                        <div class="form-group">
                            <label for="achievements">Achievements:</label>
                            <textarea class="form-control" id="achievements" type="text" formControlName="achievements"
                                name="education" placeholder="achievements"></textarea>
                        </div>


                        <br />
                        <button class="btn btn-primary" (click)="updateAbout()">update</button>

                    </form>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <small>Address</small>
                </div>
                <div class="card-body">
                    <form [formGroup]="addressForm">
                        <div class="form-group">
                            <label for="address_line_one">Address Line One:</label>
                            <input class="form-control" id="address_line_one" type="text"
                                formControlName="address_line_one" name="address_line_one"
                                placeholder="Address Line One" />
                        </div>
                        <div class="form-group">
                            <label for="address_line_two">Address Line Two:</label>
                            <input class="form-control" id="address_line_two" type="text"
                                formControlName="address_line_two" name="address_line_two"
                                placeholder="Address Line Two" />
                        </div>
                        <div class="form-group">
                            <label for="address_line_three">Address Line Three:</label>
                            <input class="form-control" id="address_line_three" type="text"
                                formControlName="address_line_three" name="address_line_three"
                                placeholder="Address Line Three" />
                        </div>
                        <div class="form-group">
                            <label for="city">City:</label>
                            <input class="form-control" id="city" type="text" formControlName="city" name="city"
                                placeholder="city" />
                        </div>
                        <div class="form-group">
                            <label for="pincode">Pincode:</label>
                            <input class="form-control" id="pincode" type="text" formControlName="pincode"
                                name="pincode" placeholder="pincode" />
                        </div>
                        <div class="form-group">
                            <label for="country">Country:</label>
                            <input class="form-control" id="country" type="text" formControlName="country"
                                name="country" placeholder="country" />
                        </div>
                        <br />
                        <button class="btn btn-primary" (click)="updateAddress()">update</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <small>Service</small>
                </div>
                <div class="card-body">
                    <form [formGroup]="serviceForm">
                    <div *ngFor="let id of  cpServiceIds; index as i" class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="service_name" (keyup)="getServiceName(id, i)" [value]="id" (change)="onCheckboxChange($event)" id="{{i}}">
                            <label class="form-check-label" for="{{i}}">
                                {{this.getServiceNames}}
                            </label>
                          </div>
                       
                    </div>
                    <br />
                    <button class="btn btn-primary" (click)="updateService()">update</button>
                </form>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <small>Therapy</small>
                </div>
                <div class="card-body">
                    <div *ngFor="let choice of therapyList; let i=index" class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" formControlName="achievements" type="checkbox" [value]="choice.therapy_id" (change)="onCheckboxTherapy($event)" id="{{choice.therapy_id}}">
                            <label class="form-check-label" for="{{choice.therapy_id}}">
                                {{choice.therapy_name}}
                            </label>
                          </div>
                    </div>
                    <br />
                    <button class="btn btn-primary">update</button>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    <small>Specialaization</small>
                </div>
                <div class="card-body">
                    <div *ngFor="let choice of specializationList; let i=index" class="col-md-8">
                        <div class="form-check">
                            <input class="form-check-input"    type="checkbox" [value]="choice.specialization_id" (change)="onCheckboxSpecialization($event)" id="{{choice.specialization_id}}">
                            <label class="form-check-label" for="{{choice.specialization_id}}">
                                {{choice.specialization_name}}
                            </label>
                          </div>
                    </div>
                    <br />
                    <button class="btn btn-primary">update</button>
                </div>
            </div>
        </div>
    </div>


</div>