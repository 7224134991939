import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service';

@Component({
  selector: 'app-cp-edit-specialization',
  templateUrl: './cp-edit-specialization.component.html',
  styleUrls: ['./cp-edit-specialization.component.css']
})
export class CpEditSpecializationComponent implements OnInit {
  multiSpecializationId: string = "";
  selectedSpecializationId: any[] = [];
  specializationList: any;
  specializationForm!: FormGroup;


  constructor(private formBuilder: FormBuilder, private service: ApiService) { }

  ngOnInit(): void {
    this.service.getSpecialization().subscribe(res => {
      this.specializationList = res?.data;
    });
        this.initSpecailizationForm();
    
  }

  initSpecailizationForm() {
    this.specializationForm = this.formBuilder.group({
      specialization_name: ['', Validators.required]

    });

  }

  onCheckboxSpecialization(evt: any) {
    const status = evt.target.checked;
    console.log(evt.target.value);
    if (status) {
      this.selectedSpecializationId.push(evt.target.value);
    } else {

      this.selectedSpecializationId = this.selectedSpecializationId.filter((v: any) => v !== evt.target.value)
    }
    this.multiSpecializationId = this.selectedSpecializationId.join(',')
    console.log(this.multiSpecializationId)

  }





}
