import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service';

@Component({
  selector: 'app-cp-edit-therapy',
  templateUrl: './cp-edit-therapy.component.html',
  styleUrls: ['./cp-edit-therapy.component.css']
})
export class CpEditTherapyComponent implements OnInit {
  therapyList: any;
  therapyForm!: FormGroup;


  multiTherapyId: string = "";
  selectedTherapyId: any[] = [];
  constructor(private formBuilder: FormBuilder, private service: ApiService) { }

  ngOnInit(): void {
    this.initTherapyForm();
    this.service.getTherapy().subscribe(res => {
      this.therapyList = res.data;
      console.log(this.therapyList);
    });
  }
  initTherapyForm() {
    this.therapyForm = this.formBuilder.group({
         therapy_name: ['', Validators.required]
    });

  }
  onCheckboxTherapy(evt: any) {
    const status = evt.target.checked;
    console.log(evt.target.value);
    if (status) {
      this.selectedTherapyId.push(evt.target.value);
    } else {

      this.selectedTherapyId = this.selectedTherapyId.filter((v: any) => v !== evt.target.value)
    }
    this.multiTherapyId = this.selectedTherapyId.join(',')
    console.log(this.multiTherapyId)

  }
}
