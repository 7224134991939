import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsComponent } from './_common/terms/terms.component';
import { AuthGuard } from './_helpers/auth.guard';
import { ForgotPasswordComponent } from './_pages/forgot-password/forgot-password.component';
import { GuestHomeComponent } from './_pages/guest-home/guest-home.component';
import { PrivacyComponent } from './_pages/privacy/privacy.component';
import { SubscriptionComponent } from './_pages/subscription/subscription.component';
import { TestFbLoginComponent } from './_pages/test-fb-login/test-fb-login.component';
import { UserAccountComponent } from './_pages/user-account/user-account.component';
import { UserHomeComponent } from './_pages/user-home/user-home.component';
import { UserJourneyComponent } from './_pages/user-journey/user-journey.component';
import { UserNotificationsComponent } from './_pages/user-notifications/user-notifications.component';
import { CoachMoodTrackerComponent } from './_pages/coach/coach-mood-tracker/coach-mood-tracker.component';
import { ActivateUserAccountComponent } from './_pages/activate-user-account/activate-user-account.component';
import { ResetPasswordComponent } from './_pages/reset-password/reset-password.component';
import { ApexChartsComponent } from './_pages/apex-charts/apex-charts.component';
import { PartnerTermsPrivacyComponent } from './_pages/partner-terms-privacy/partner-terms-privacy.component';
import { UserFavouriteModuleComponent } from './_pages/user-favourite-module/user-favourite-module.component';

const routes: Routes = [
  { path: '',   component: GuestHomeComponent },
  { path: 'home',   component: GuestHomeComponent },
  { path: 'user-home',   component: UserHomeComponent,  canActivate: [AuthGuard] },
  { path: 'fb-login',   component: TestFbLoginComponent },

  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'my-account', component: UserAccountComponent, canActivate: [AuthGuard]},
  { path: 'blog', loadChildren: () => import('./_pages/blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'onmood-course', loadChildren: () => import('./_pages/courses/courses.module').then(m => m.CoursesModule) },
  { path: 'my-notifications', component: UserNotificationsComponent, canActivate: [AuthGuard]},

  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'activate-user-account', component: ActivateUserAccountComponent},
  { path: 'my-journey', component: UserJourneyComponent,  canActivate: [AuthGuard]},
  { path: 'subscription', component: SubscriptionComponent},
  { path: 'privacy', component: PrivacyComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'coachaddfeeling',component:CoachMoodTrackerComponent, canActivate: [AuthGuard]},
  { path: 'apex',component:ApexChartsComponent},
  { path: 'partner-login/:type/:partnerApp/:cid/:encrypted',component:PartnerTermsPrivacyComponent},
  { path: 'test',component:TestFbLoginComponent},
  { path: 'my-favourites',component:UserFavouriteModuleComponent, canActivate: [AuthGuard]},


  { 
    path: 'assessments', 
    loadChildren: () => import('./_pages/assessments/assessments.module').then(m => m.AssessmentsModule),
    canActivate: [AuthGuard]
  },
  { path: 'moodtracker', 
    loadChildren:()=>import('./_pages/mood-tracker/mood-tracker.module').then(a => a.MoodTrackerModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
