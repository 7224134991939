import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderComponent } from './_common/header/header.component';
import { FooterComponent } from './_common/footer/footer.component';
import { AuthenticationService } from './services/authentication.service';
import { ApiService } from './services/api-service';
import { GuestHomeModule } from './_pages/guest-home/guest-home.module';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordModule } from './_pages/forgot-password/forgot-password.module';
import { UserHomeModule } from './_pages/user-home/user-home.module';
import HttpAPIInterceptor from './services/httpAPIInterceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UserAccountComponent } from './_pages/user-account/user-account.component';
import { UserNotificationsComponent } from './_pages/user-notifications/user-notifications.component';
import { UserJourneyComponent } from './_pages/user-journey/user-journey.component';
import { TestFbLoginComponent } from './_pages/test-fb-login/test-fb-login.component';
import { StorageService } from './services/storage.service';
import { CoachMoodTrackerComponent } from './_pages/coach/coach-mood-tracker/coach-mood-tracker.component';
import { ResetPasswordModule } from './_pages/reset-password/reset-password.module';
import { ApexChartsModule } from './_pages/apex-charts/apex-charts.module';
import { PrivacyTermsPrivacyModule } from './_pages/partner-terms-privacy/partner-terms-privacy.module';
import { SubscriptionService } from './services/subscription.service';
import { LocalStorageService } from './services/local-storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { UserFavouriteModuleModule } from './_pages/user-favourite-module/user-favourite-module.module';
import { FACEBOOK_APP_ID } from './constants/Constants';
import { HelperService } from './services/helper.service';
import { CareProviderModule } from './_pages/careProvider/care-provider/care-provider.module';
import { ProfileDataService } from './services/profile-data.service';
import { CpLoginComponent } from './_pages/careProvider/cp-login/cp-login.component';
import { CpEditComponent } from './_pages/careProvider/cp-edit/cp-edit.component';
import { CpEditAboutComponent } from './_pages/careProvider/cp-edit-about/cp-edit-about.component';
import { CpEditAddressComponent } from './_pages/careProvider/cp-edit-address/cp-edit-address.component';
import { CpEditBasicComponent } from './_pages/careProvider/cp-edit-basic/cp-edit-basic.component';
import { CpEditServiceComponent } from './_pages/careProvider/cp-edit-service/cp-edit-service.component';
import { CpEditTherapyComponent } from './_pages/careProvider/cp-edit-therapy/cp-edit-therapy.component';
import { CpEditSpecializationComponent } from './_pages/careProvider/cp-edit-specialization/cp-edit-specialization.component';
import { BaseComponent } from './_pages/base/base.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UserAccountComponent,
    UserNotificationsComponent,
    UserJourneyComponent,
    TestFbLoginComponent,
    CoachMoodTrackerComponent,
    BaseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    GuestHomeModule,
    UserHomeModule,
    UserFavouriteModuleModule,
    HttpClientModule,
    ApexChartsModule,
    PrivacyTermsPrivacyModule,
    NgxMaterialTimepickerModule,
    CareProviderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [AuthenticationService, ApiService, SocialAuthService,
    SubscriptionService,
    LocalStorageService,
    StorageService,
    HelperService,
    ProfileDataService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(FACEBOOK_APP_ID)
          }
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAPIInterceptor,
      multi: true
    }
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }  
