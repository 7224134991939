import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CareProviderRoutingModule} from "./care-provider.routing.module";
import { BrowserModule } from '@angular/platform-browser';
import { CareProviderComponent } from './care-provider.component';
import { SearchFormComponent } from '../search-form/search-form.component';
import { DoctorSidebarComponent } from '../doctor-sidebar/doctor-sidebar.component';
import { ContentComponent } from '../content/content.component';
import { DoctorDetailsComponent } from '../doctor-details/doctor-details.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ServicesComponent } from '../services/services.component';
import { WhyUsComponent } from '../why-us/why-us.component';
import { WorkProcessComponent } from '../work-process/work-process.component';
import { TeamComponent } from '../team/team.component';
import { CpReviewComponent } from '../cp-review/cp-review.component';
import { FilterPipe } from 'src/app/filter.pipe';
import { CpEditBasicComponent } from '../cp-edit-basic/cp-edit-basic.component';
import { CpEditAboutComponent } from '../cp-edit-about/cp-edit-about.component';
import { CpEditAddressComponent } from '../cp-edit-address/cp-edit-address.component';
import { CpLoginComponent } from '../cp-login/cp-login.component';
import { CpEditComponent } from '../cp-edit/cp-edit.component';
import { CpEditServiceComponent } from '../cp-edit-service/cp-edit-service.component';
import { CpEditTherapyComponent } from '../cp-edit-therapy/cp-edit-therapy.component';
import { CpEditSpecializationComponent } from '../cp-edit-specialization/cp-edit-specialization.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    CareProviderComponent,
    SearchFormComponent,
    DoctorSidebarComponent,
    ContentComponent,
    DoctorDetailsComponent,
    ServicesComponent,
    WhyUsComponent,
    WorkProcessComponent,
    TeamComponent,
    CpReviewComponent,
    FilterPipe,
    CpLoginComponent,
    CpEditComponent,
    CpEditAboutComponent,
    CpEditAddressComponent,
    CpEditBasicComponent,
    CpEditServiceComponent,
    CpEditTherapyComponent,
    CpEditSpecializationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CareProviderRoutingModule,
    NgxPaginationModule,
    SlickCarouselModule
  
   
  ],
  providers: [FilterPipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CareProviderModule { }
