import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CpProfileService {
    constructor(private http: HttpClient) { }
    public getRequest(url: string) {
        return this.http.get<any>(url);
    }
    public postRequest(url: string, requestPayload: any): Observable<any> {
        return this.http.post<any>(url, requestPayload);
    }
}