<div class="card">
    <div class="card-header">
        <small>Address</small>
    </div>
    <div class="card-body">
        <form [formGroup]="editAddressForm">
            <div class="form-group">
                <label for="address_line_one">Address Line One:</label>
                <input class="form-control" id="address_line_one" type="text"
                    formControlName="address_line_one" name="address_line_one"
                    placeholder="Address Line One" />
            </div>
            <div class="form-group">
                <label for="address_line_two">Address Line Two:</label>
                <input class="form-control" id="address_line_two" type="text"
                    formControlName="address_line_two" name="address_line_two"
                    placeholder="Address Line Two" />
            </div>
            <div class="form-group">
                <label for="address_line_three">Address Line Three:</label>
                <input class="form-control" id="address_line_three" type="text"
                    formControlName="address_line_three" name="address_line_three"
                    placeholder="Address Line Three" />
            </div>
            <div class="form-group">
                <label for="city">City:</label>
                <input class="form-control" id="city" type="text" formControlName="city" name="city"
                    placeholder="city" />
            </div>
            <div class="form-group">
                <label for="pincode">Pincode:</label>
                <input class="form-control" id="pincode" type="text" formControlName="pincode"
                    name="pincode" placeholder="pincode" />
            </div>
            <div class="form-group">
                <label for="country">Country:</label>
                <input class="form-control" id="country" type="text" formControlName="country"
                    name="country" placeholder="country" />
            </div>
            <br />
            <button class="btn btn-primary" (click)="updateAddress()">update</button>
        </form>
    </div>
</div>