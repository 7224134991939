import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service';
import { CpProfileService } from '../cp-profile-service';
import { GET_SERVICES } from 'src/app/constants/ApiEndPoints';

@Component({
  selector: 'app-cp-edit-service',
  templateUrl: './cp-edit-service.component.html',
  styleUrls: ['./cp-edit-service.component.css']
})
export class CpEditServiceComponent implements OnInit {
  @Input() type!: string;
  @Output() setNextPage: EventEmitter<any> = new EventEmitter();
  @Input() selectedProfile!: any;
  addedCpserviceList: any[] = [];
  buttonText: string = "Save";

  cpServiceList: any[] = [];

  constructor(private cpProfileService: CpProfileService) {}

  ngOnInit(): void {
    if(this.type === 'edit') this.buttonText = "Update";
    this.cpProfileService.getRequest(GET_SERVICES).subscribe((res: any) => {
      // console.log(res.data);
      
      this.cpServiceList = res.data;
      this.addedCpserviceList = this.selectedProfile?.cp_service_id?.split(',') || [];
    })
  }

  onCheckboxChange(evt: any, service_id: string) {
    const status = evt.target.checked;
    // console.log(evt.target.value);
    const index = this.addedCpserviceList.indexOf(service_id);
    if (status) {
      if(index === -1) this.addedCpserviceList.push(evt.target.value);
    } else {
      if(index !== -1) this.addedCpserviceList.splice(index, 1);
    }
    // console.log(this.addedCpserviceList.toString())

  }

  saveServices() {
    const payload = {
      cp_profile_id: this.selectedProfile?.cpProfileId,
      cp_service_id: this.addedCpserviceList.toString()
    }
    const url = '';//.this.type === 'edit' ? UPDATE_CP_SERVICE : SAVE_CP_SERVICE;
    this.cpProfileService.postRequest(url, payload).subscribe((res: any) => {
      if(this.type === 'edit') {
        alert("updated successfully");
      } else {
        // sendNextPageName
        // this.setNextPage.emit('ADD_ADDRESS');
      }
    });
  }
}
