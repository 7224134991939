<!-- <div class="card">
    <div class="card-header">
        <small>Service</small>
    </div>
    <div class="card-body">
        <div *ngFor="let choice of cpServiceList; let i=index" class="col-md-6">
            <label>
                <input class="form-check-input" 
                type="checkbox" [value]="choice.service_id"
                (change)="onCheckboxChange($event, choice.service_id)"
                [checked]="addedCpserviceList.indexOf(choice.service_id) >= 0"
                >
                {{choice.service_name}}
            </label>
        </div>
        <br />
        <button class="btn btn-primary" (click)="saveServices()">{{buttonText}}</button>
    </div>
</div> -->

<div class="card">
    <div class="card-header">
        <small>Service</small>
    </div>
    <div class="card-body">
        <div *ngFor="let choice of cpServiceList; let i=index" class="col-md-12">
            <div class="form-check">
                <input class="form-check-input" 
                    formControlName="achievements" 
                    type="checkbox" 
                    [value]="choice.therapy_id" 
                    (change)="onCheckboxChange($event, choice.service_id)" 
                    [checked]="addedCpserviceList.indexOf(choice.service_id) >= 0"
                    id="{{choice.therapy_id}}">
                <label class="form-check-label" for="{{choice.service_id}}">
                    {{choice.service_name}}
                </label>
              </div>
        </div>
        <br />
        <button class="btn btn-primary">update</button>
    </div>
</div>