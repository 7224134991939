import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service';
import { ProfileDataService } from 'src/app/services/profile-data.service';

@Component({
  selector: 'app-cp-login',
  templateUrl: './cp-login.component.html',
  styleUrls: ['./cp-login.component.css']
})
export class CpLoginComponent implements OnInit {
  loginform!: FormGroup;
  phone_number: string;
  password: string;
  errorMessage = '';
  selectedProfile: any;
  constructor(private formBuilder: FormBuilder, private service: ProfileDataService,public router: Router) { }

  ngOnInit(): void {

  }

  logInUser() {
    // 9874563212
    // NJuMtk7aH2POxznQ
    this.service.cpLogin(this.phone_number, this.password).subscribe(response => {
      if (response.result === 'success' && response.accounts.length > 0) {
        console.log("welcome to dashboard");
        console.log(response.accounts[0].cp_profile_id);
        this.selectedProfile = response.accounts[0].cp_profile_id;
        const cp_profile_id = response.accounts[0].cp_profile_id;
         this.router.navigate(["/care-provider/cp-edit/"+cp_profile_id]);

      } else {
        this.errorMessage = 'User Unauthorized';        
        console.log("User Unauthorized");
      }

    });

  }
}
