<div class="card">
    <div class="card-header">
        <small>Specialaization</small>
    </div>
    <div class="card-body">
        <div *ngFor="let choice of specializationList; let i=index" class="col-md-8">
            <div class="form-check">
                <input class="form-check-input"    type="checkbox" [value]="choice.specialization_id" (change)="onCheckboxSpecialization($event)" id="{{choice.specialization_id}}">
                <label class="form-check-label" for="{{choice.specialization_id}}">
                    {{choice.specialization_name}}
                </label>
              </div>
        </div>
        <br />
        <button class="btn btn-primary">update</button>
    </div>
</div>