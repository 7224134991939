import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit {
  topic = '';
location: any;
  constructor(private route: Router) { }
  searchForm = new FormGroup({
    topic: new FormControl(''),
    location: new FormControl(''),
  });
  onSubmit() {
    
   if(this.searchForm.value.topic === ''){
    this.searchForm.controls['topic'].setValue('ALL');
    this.route.navigate(['/doctors-grid/'+this.searchForm.value.topic+"/"+this.searchForm.value.location]);
   }else{
    this.searchForm.controls['location'].setValue('ALL');
    this.route.navigate(['/doctors-grid/'+this.searchForm.value.topic+"/"+this.searchForm.value.location]);
   }
    this.searchForm.reset();
  }
  ngOnInit(): void {

  }

}
