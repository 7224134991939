<div class="container">
    <div>
        <button type="button" class="btn btn-primary" (click)="goBack()">Go Back</button>
    </div><br>
    
    <div class="row">
        <div class="col-md-4">
            <app-cp-edit-basic *ngIf="selectedProfile" [selectedProfile]="selectedProfile"></app-cp-edit-basic>
        </div>
        <div class="col-md-4">
            <app-cp-edit-about *ngIf="selectedProfile" 
                [selectedProfile]="selectedProfile"></app-cp-edit-about>
        </div>
        <div class="col-md-4">
            <app-cp-edit-address *ngIf="selectedProfile" 
                [selectedProfile]="selectedProfile"></app-cp-edit-address>
        </div>
        <div class="col-md-4">
            <app-cp-edit-service *ngIf="selectedProfile" 
                [selectedProfile]="selectedProfile"></app-cp-edit-service>
        </div>
        <div class="col-md-4">
            <app-cp-edit-therapy *ngIf="selectedProfile" 
                [selectedProfile]="selectedProfile"></app-cp-edit-therapy>
        </div>
        <div class="col-md-4">
            <app-cp-edit-specialization *ngIf="selectedProfile" 
                [selectedProfile]="selectedProfile"></app-cp-edit-specialization>
        </div>
    </div>
</div>